export enum assignmentStatus {
  New = "New",
  Rejected = "Rejected",
  Accepted = "Accepted",
  Realized = "Realised",
  NotRealized = "NotRealised",
  PartiallyRealized = "PartiallyRealized",
}
export enum overtimeTypeEnum {
  TimeToPay = "ToPay",
  TimetoCollection = "ToCollection",
}
export enum userRoleEnum {
  Admin = "Admin",
  Employee = "Employee",
}
export enum assignmentStatusPl {
  New = "Nowe",
  Rejected = "Odrzucone",
  Accepted = "Zaakceptowane",
  Realized = "Zrealizowane",
  NotRealized = "Niezrealizowane",
}
export enum applicationTypeEnum {
  Overtime = "Overtime",
  Leaving = "Leaving",
}
export enum documentTypeEnum {
  Commision = "Commision",
  Application = "Application",
}
export enum localStorageAccessorEnum {
  Filters = "filters"
}
export enum activityStatus {
  Active = "Active",
  Inactive = "Inactive",
}
export enum activityStatusPl {
  Active = "Dostępny",
  Inactive = "Niedostępny",
}
