import axiosInstance from "../utils/axios";
import {
  IUsersData,
  IUserSelectable,
  IUserDataResponse,
} from "./types/users";
import { IOrgUsersData, IUser } from "./types/table";
import { appSuccessToast } from "../components/common";
import appErrorToast from "../components/common/AppErrorToast";
import { Dispatch, SetStateAction } from "react";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api`;

class UsersService {
  getSelf(): Promise<IUserDataResponse> {
    return axiosInstance
      .get<IUserDataResponse>(`${pathPrefix}/Users/self`)
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  getUsers(): Promise<IUsersData> {
    return axiosInstance
      .get<IUsersData>(`${pathPrefix}/Users`)
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  getOrgUsers(assigned: boolean, skip: number): Promise<IOrgUsersData> {
    const loadRows = 10;
    return axiosInstance
      .get<IOrgUsersData>(
        `${pathPrefix}/Users/list?type=${
          assigned ? "assigned" : "unassigned"
        }&skip=${skip}&rows=${loadRows}`
      )
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  addUsersToApp(selected: IUser[], SetIsAdding: Dispatch<SetStateAction<boolean>>) {
    const UsersIds = selected.map((e) => {
      return e.id;
    });
    if (UsersIds.length < 1) {
      appErrorToast("Wybierz pracowników");
      SetIsAdding(false);
      return;
    }
    axiosInstance
      .post(`${pathPrefix}/Users`, {
        Ids: UsersIds,
      })
      .then(() => {
        appSuccessToast("Użytkownicy dodani do aplikacji");
        setTimeout(() => {
          SetIsAdding(false);
          window.location.reload();
        }, 1000);
      });
  }
  
  selectableUsers(): Promise<IUserSelectable[]> {
    return axiosInstance
      .get<IUserSelectable[]>(`${pathPrefix}/Users/list/selectable`)
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  deleteUser(userId: string) {
    return axiosInstance
      .delete(`${pathPrefix}/Users/${userId}`)
      .then(() => {
        appSuccessToast("Użytkownik został pomyślnie usunięty");
      });
  }
}

const usersService = new UsersService();
export default usersService;
