import { createContext, useState, useEffect } from "react";
import usersService from "../../services/users.service";
import { IUserDataResponse } from "../../services/types/users";
import { AppLoader } from "../../components/common";
import { LandingPageLayout } from "../../components/layouts";
import WarningPage from "../WarningPage";
import { app } from "@microsoft/teams-js";
import AppInstruction from "../Shared/AppInstruction";

const UserContext = createContext({} as IUserDataResponse);

export function UserContextProvider(props: { children: React.ReactNode }) {
  const [userData, setUserData] = useState({} as IUserDataResponse);
  const [isLoading, setIsLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  const getSelf = async () => {
    try {
      await app.initialize();
    } catch (error) {
      setShowInfo(true);
      return;
    }
    usersService.getSelf().then((data: IUserDataResponse) => {
      setUserData(data);
      setIsLoading(false);
    });
  };
  const checkLocation = () => {
    if (window.location.pathname === "/quick-start")  return true;
    return false;
  }

  useEffect(() => {
    getSelf();
  }, []);

  return (
    <>
      {!showInfo && (
        <UserContext.Provider value={userData}>
          {isLoading && !showInfo ? (
            <LandingPageLayout>
              <AppLoader />
            </LandingPageLayout>
          ) : (
            props.children
          )}
        </UserContext.Provider>
      )}
      {showInfo && !checkLocation() && <WarningPage />}
      {showInfo && checkLocation() && <AppInstruction />}
    </>
  );
}

export default UserContext;
