import axios from "axios";
import { app, authentication } from "@microsoft/teams-js";
import errorHandler from "./errorHandler";

const getClientToken = async () => {
  await app.initialize();
  const token = await authentication.getAuthToken();
  return token;
};

const service = axios.create();

service.interceptors.request.use(
  async (config) => {
    const token = await getClientToken();
    config.headers.common["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    errorHandler(error);
    return Promise.reject(error);
  }
);

export default service;
