import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  shorthands,
  useId,
  Input,
  Label,
  InputProps,
} from "@fluentui/react-components";
import { Edit24Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import dataTimeService from "../../services/dataTime.service";
import {
  ITimeToPay,
  IOvertimeDialogProps,
} from "../../services/types/overtime";
import { overtimeSummariesService } from "../../services";
import { AppButton, AppErrorToast } from "../common";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    maxWidth: "500px",
  },
  input: {
    minWidth: "100%",
    marginBottom: "1rem",
  },
  button: {
    cursor: "pointer"
  }
});

const initialState: ITimeToPay = {
  timeToCollection: 0,
};
const OvertimeDialog: React.FC<IOvertimeDialogProps> = ({
  timeToCollection,
  timeToCollectionySummary,
  getOvertime,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputTimeToCollection = useId("TimeToCollection");
  const [summary, setSummary] = useState(initialState);
  const [timeTxt, setTimeTxt] = useState("01:00");
  const styles = useStyles();

  const handleTimeToCollection: InputProps["onChange"] = (_, data) => {
    setTimeTxt(data.value);

    const timeInMin = dataTimeService.convertTimeToMin(data.value);
    if (timeToCollectionySummary !== undefined && timeInMin > timeToCollectionySummary) {
      AppErrorToast("Nie masz tylu godzin do odbioru");
    } else {
      setSummary({ ...summary, timeToCollection: timeInMin });
    }
  };
  const saveChanges = () => {
    const timeToCollection = dataTimeService.convertStringToMin(timeTxt);
    if (timeToCollection) {
      setIsLoading(true);
      summary.timeToCollection = timeToCollection;
      overtimeSummariesService.setOvertimeSummary(summary).then(() => {
        setOpen(false);
        getOvertime();
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    setSummary({
      timeToCollection: timeToCollection ? timeToCollection : 0,
    });
  }, [timeToCollection]);
  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <Edit24Regular className={styles.button}/>
      </DialogTrigger>
      <DialogSurface className={styles.root}>
        <DialogBody>
          <DialogTitle>Edytuj odebrane nadgodziny</DialogTitle>
          <DialogContent>
            <div className={styles.root}>
              <Label htmlFor={inputTimeToCollection}>
                Liczba odebranych nadgodzin
              </Label>
              <Input
                value={timeTxt}
                type="text"
                onChange={handleTimeToCollection}
                id={inputTimeToCollection}
                className={styles.input}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Zamknij</Button>
            </DialogTrigger>
            <AppButton
              text="Zapisz"
              disabled={isLoading}
              appearance="primary"
              onClick={saveChanges}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default OvertimeDialog;
