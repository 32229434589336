import { AxiosError } from "axios";
import { AppErrorToast } from "../components/common";

export default function errorHandler(error: AxiosError) {
  if (error.response?.status === 400) {
    AppErrorToast("Nieprawidłowe zapytanie");
  } else if (error.response?.status === 401) {
    AppErrorToast("Nieautoryzowany dostęp");
  } else if (error.response?.status === 404) {
    AppErrorToast("Błąd komunikacji z serwerem");
  } else if (error.response?.status === 500) {
    AppErrorToast("Błąd serwera");
  } else {
    AppErrorToast("Błąd zapytania");
  }
}
