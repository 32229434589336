import axiosInstance from "../utils/axios";
import { ITimeToPay } from "./types/overtime";
import { appSuccessToast } from "../components/common";
import {
    IOvertimeSummaries,
} from "./types/users";
import { ICollectedTimeResponse} from "./types/table";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api`;

class OvertimeSummariesService {

    getOvertimeSummaries(): Promise<IOvertimeSummaries> {
        return axiosInstance
          .get<IOvertimeSummaries>(`${pathPrefix}/overtime-summaries`)
          .then((response) => {
            return Promise.resolve(response.data);
          });
    }

    setOvertimeSummary(summary: ITimeToPay): Promise<void> {
        return axiosInstance
          .put(`${pathPrefix}/overtime-summaries`, {
            TimeToCollection: summary.timeToCollection,
          })
          .then(() => {
            appSuccessToast("Nadgodziny zostały zaktualizowane");
          });
    }

    getCollectedTime(skip: number, userId?: string) {
        const rows = 10;
        const appendId = userId && userId !== "Wszyscy" ? `&id=${userId}` : '';
        return axiosInstance
          .get<ICollectedTimeResponse>(
            `${pathPrefix}/Users/collected-time/list?skip=${skip}&rows=${rows}${appendId}`
          )
          .then((response) => {
            return Promise.resolve(response.data);
          });
    }

    browseLeavings(skip: number, userId?: string) {
      const rows = 10;
      const appendId = userId && userId !== "Wszyscy" ? `&id=${userId}` : '';
      return axiosInstance
        .get<ICollectedTimeResponse>(
          `${pathPrefix}/Users/leavings/list?skip=${skip}&rows=${rows}${appendId}`
        )
        .then((response) => {
          return Promise.resolve(response.data);
        });
    }
      
    deleteCollectedTime(id: string) {
    return axiosInstance.delete(`${pathPrefix}/overtime-summaries/${id}`)
    .then(() => {
        appSuccessToast("Wpis został pomyślnie usunięty");
    })
    }
}

const overtimeSummariesService = new OvertimeSummariesService();
export default overtimeSummariesService;
