import { CenteredLayout } from "../../../components/layouts";
import useWorktimeHistory from "../../../hooks/useWorktimeHistory";
import { Card, CardPreview } from "@fluentui/react-components/unstable";
import AdminNavbar from "../../../components/layouts/AdminNavbar";
import PageTitle from "../../../components/common/PageTitle";
import WorktimeHistoryList from "../../../components/layouts/WorktimesHistoryList";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  reducedHeight: {
    height: '65%',
  },
});

const WorktimeHistory = () => {
  const { worktimes, getWorktimeHistory, isLoading } =  useWorktimeHistory();
  const classes = useStyles();

  return (
    <>
      <AdminNavbar />
      <CenteredLayout>
        <PageTitle title="Historia czasu pracy" />
        <Card className={classes.reducedHeight}>
          <CardPreview>
            <WorktimeHistoryList
              tableHeadTxt="Dane dotyczące daty i godziny rozpoczęcia oraz zakończenia pracy"
              items={worktimes}
              getMoreItems={getWorktimeHistory}
              isLoading={isLoading}
            />
          </CardPreview>
        </Card>
      </CenteredLayout>
    </>
  );
};

export default WorktimeHistory;
