import {
  makeStyles,
  Select,
  SelectProps,
  useId,
  Label,
} from "@fluentui/react-components";
import { FieldValues } from "react-hook-form";
import { IUserSelectable } from "../../services/types/users";

interface IAppUserSelect {
  label: string;
  options: IUserSelectable[];
  formHandler: FieldValues;
}

const useStyles = makeStyles({
  root: {
    width: "240px",
  },
});

export default function AppUserSelect(props: IAppUserSelect & SelectProps) {
  const styles = useStyles();
  const dropdownId = useId("user-select");
  const { label, options, formHandler, ...rest } = props;

  return (
    <div>
      <Label id={dropdownId}>{label}</Label>
      <Select
        aria-labelledby={dropdownId}
        className={styles.root}
        {...formHandler}
        {...rest}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.lastName + " " + option.firstName}
          </option>
        ))}
      </Select>
    </div>
  );
}
