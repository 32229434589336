import {
  makeStyles,
  SelectProps,
  useId,
  Label,
  Textarea,
} from "@fluentui/react-components";
import { FieldValues } from "react-hook-form";

interface IAppTextArea {
  label: string;
  formHandler: FieldValues;
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column"
  }
});

export default function AppTextArea(props: IAppTextArea & SelectProps) {
  const styles = useStyles();
  const textareaId = useId("textarea");
  const { label, formHandler} = props;

  return (
    <div className={styles.container}>
      <Label id={textareaId}>{label}</Label>
      <Textarea
        aria-labelledby={textareaId}
        className={styles.root}
        {...formHandler}
      />
    </div>
  );
}
