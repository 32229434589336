import { Input, Label } from "@fluentui/react-components";
import "./InAndOutBarStyles.css";
import { dataTimeService } from "../../../../services";
import { useCallback } from "react";

interface IInAndOutBarProps {
  onDataChanged: (date: string) => void;
  date: string;
  totalWorkTime: string;
}
const InAndOutBar = (props: IInAndOutBarProps) => {
  const onDataChanged = useCallback((date: string) => {
    props.onDataChanged(date);
  }, []);

  return (
    <div className="callendar-bar">
      <div className="callendar-bar-input">
        <Label className="callendar-bar-label">Wybierz date</Label>
        <Input
          onChange={(date) => onDataChanged(date.target.value)}
          type="date"
          defaultValue={props.date}
          max={dataTimeService.todayString()}
        />
      </div>
      <div className="callendar-bar-time">
        <div className="callendar-bar-label">Czas pracy:</div>
        <div>{props.totalWorkTime ?? "00:00"}</div>
      </div>
    </div>
  );
};

export default InAndOutBar;
