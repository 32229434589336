import { AppHeader } from "../components/common";
import { LandingPageLayout } from "../components/layouts";

const WarningPage: React.FC = () => {
  return (
    <LandingPageLayout>
        <AppHeader text="Aplikacja musi być uruchomiona wewnątrz platformy Teams, co oznacza, że nie będzie działać samodzielnie jako oddzielne oprogramowanie." />
        <AppHeader text="Aby korzystać z aplikacji, upewnij się, że masz zainstalowaną i uruchomioną platformę Teams na swoim urządzeniu." />
    </LandingPageLayout>
  );
};

export default WarningPage;
