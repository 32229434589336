import { CenteredLayout } from "../../../components/layouts";
import {
  AddAssignmentDialog,
  AssignmentsList,
} from "../../../components/layouts";
import useAsignments from "../../../hooks/useAsignments";
import { Card, CardPreview } from "@fluentui/react-components/unstable";
import AdminNavbar from "../../../components/layouts/AdminNavbar";
import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";

const ManageAssigments = () => {
  const { assignments, getAssignments, isLoading, setAssignments } =
    useAsignments();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <AdminNavbar />
      <CenteredLayout>
        <PageTitle title="Wnioski i zlecenia" />
        <AddAssignmentDialog
          open={openDialog}
          setOpen={setOpenDialog}
          getAssignments={getAssignments}
          setAssignments={setAssignments}
        />
        <Card>
          <CardPreview>
            <AssignmentsList
              tableHeadTxt="Zlecenia i wnioski wyjść oraz nadgodzin"
              items={assignments}
              getMoreUsers={getAssignments}
              isLoading={isLoading}
              setOpendialog={setOpenDialog}
            />
          </CardPreview>
        </Card>
      </CenteredLayout>
    </>
  );
};

export default ManageAssigments;
