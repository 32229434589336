import { WorktimeInAndOut } from "../../../services/worktime.service";
import "./InAndOutCard.css";

interface IWorktimeInAndOutProps {
  inAndOut: WorktimeInAndOut;
}

const InAndOutCard = (props: IWorktimeInAndOutProps) => {
  return (
    <>
      {props.inAndOut.endTime ? (
        <div className="event exit">
          <div className="time">{props.inAndOut.endTime}</div>
          <div className="title">Wyjście</div>
          <div className="date">{props.inAndOut.endTimeDate}</div>
          <div className="description">
            Łączny czas pracy: {props.inAndOut.worktimeSpan}
          </div>
        </div>
      ) : null}
      <div className="event entry">
        <div className="time">{props.inAndOut.startTime}</div>
        <div className="title">Wejście</div>
        <div className="date">{props.inAndOut.startTimeDate}</div>
        {props.inAndOut.endTime ? null : (
          <div className="description">
            Łączny czas pracy: {props.inAndOut.worktimeSpan}
          </div>
        )}
      </div>
    </>
  );
};

export default InAndOutCard;
