import styles from "./EmplyeesListStyles";
import {
  useScrollbarWidth,
  useFluent,
  makeStyles,
} from "@fluentui/react-components";
import * as React from "react";
import { FixedSizeList as List } from "react-window"; 
import {
  TableBody,
  TableRow,
  Table,
  TableHeaderCell,
  createTableColumn,
  useTableFeatures,
  TableRowData as RowStateBase,
  TableRowId,
  useTableSelection,
} from "@fluentui/react-components/unstable";
import { WorktimeHistoryListRow } from "../businessComponenets/";
import InfiniteLoader from "react-window-infinite-loader";
import {
  IWorktimeHistoryTabRow,
  IWorktimeHistoryTabProps,
} from "../../services/types/table";
import { AppLoader} from "../common";
import { useEffect, useState } from "react";
import { IFiltersData, IGetWorktimeHistoryOptions } from "../../services/types/worktimeHistory";
import { worktimeHistoryService } from "../../services";
import { useHistory } from 'react-router-dom';
import WorktimeHistoryFilters from "./WorktimeHistoryFilters";

interface TableRowData extends RowStateBase<IWorktimeHistoryTabRow> {
  onClick: (e: React.MouseEvent) => void;
  selected: boolean;
  appearance: "brand" | "none";  
  getWorktimeHistory: () => void
}

const columns =  [
  createTableColumn<IWorktimeHistoryTabRow>({
    columnId: "userName",
  }),
  createTableColumn<IWorktimeHistoryTabRow>({
    columnId: "worktimeBeginning",
  }),
  createTableColumn<IWorktimeHistoryTabRow>({
    columnId: "worktimeFinish",
  }),
  createTableColumn<IWorktimeHistoryTabRow>({
    columnId: "totalWorktimeFormatted",
  }),
];

const useStyles = makeStyles({
  tableCell: {
    minWidth: "126px"
  },
})

const WorktimeHistoryList: React.FC<IWorktimeHistoryTabProps> = ({
  tableHeadTxt,
  items,
  getMoreItems,
  isLoading,
}) => {

  const { targetDocument } = useFluent();
  const history = useHistory();
  const scrollbarWidth = useScrollbarWidth({targetDocument});
  const classes = useStyles();
  const [filtersData, setFiltersData] = useState<IFiltersData>();
  const [listHeight, setListHeight] = useState(window.innerHeight * 0.425);

  useEffect(() => {
    const handleResize = () => {
      setListHeight(window.innerHeight * 0.425);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { 
    getRows,
    selection: { toggleRow, isRowSelected },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSelection({
        selectionMode: "multiselect",
      }),
    ]
  );

  const updateFilters = (filters: IFiltersData) => {
    setFiltersData(filters);
  }

  const showDetails = (e: React.MouseEvent, rowId: TableRowId) => {
    const allRows = getRows();
    const item = allRows.find((e) => {
      return e.rowId === rowId;
    });
    const worktimeId = item?.item?.worktimeStartId;  

    history.push(`/admin/registered-time-history/${worktimeId}/details`);
  };

  const rows: TableRowData[] = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
        ...row,
        onClick: (e: React.MouseEvent) => {
          showDetails(e, row.rowId);
        },
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === " ") {
            e.preventDefault();
            toggleRow(e, row.rowId);
          }
        },
        selected,
        appearance: selected ? ("brand" as const) : ("none" as const),
        getWorktimeHistory: () => {
          items.length = 0;
          const worktimeHistoryOptions: IGetWorktimeHistoryOptions = {
            stopIndex: 0,
            reload: true,
            filters: filtersData
          }

          getMoreItems(worktimeHistoryOptions);
        },
    };
  });

  const isItemLoaded = () => false

  const loadMoreItems = (_: number, stopIndex: number) => {
    const worktimeHistoryOptions: IGetWorktimeHistoryOptions = {
      stopIndex: stopIndex,
      reload: false,
      filters: filtersData
    }
    getMoreItems(worktimeHistoryOptions);
  };

  useEffect(() => {
    const worktimeHistoryOptions: IGetWorktimeHistoryOptions = {
      stopIndex: 0,
      reload: true,
      filters: filtersData
    }
    getMoreItems(worktimeHistoryOptions);
  }, [filtersData]);
  
  return (
    <div>
      <div>
        <div style={styles.assignmentsTableHeader}>
          <div style={styles.tableHeaderWrapper}>
            <h3 style={styles.tableHeadTxt}>{tableHeadTxt}</h3>
          </div>
          <WorktimeHistoryFilters 
            setFiltersData={updateFilters}
          />
        </div>
      </div>
      <div className="main-table-container">
        <Table
          noNativeElements
          aria-label="Table with selection"
          aria-rowcount={rows.length}
          style={styles.table}
        >
          <TableBody className="tableBody">
            <TableRow aria-rowindex={1}>
              <TableHeaderCell className={classes.tableCell}>Nazwisko i imię</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Rozpoczęcie dnia pracy</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Zakończenie dnia pracy</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Łączny czas pracy</TableHeaderCell>
              <div
                role="presentation"
                style={{
                  width: scrollbarWidth,
                }}
              />
            </TableRow>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={items.length}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={listHeight}
                  itemCount={items.length}
                  itemSize={45}
                  width="100%"
                  itemData={rows}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {WorktimeHistoryListRow}
                </List>
              )}
            </InfiniteLoader>
            {isLoading && <AppLoader />}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default WorktimeHistoryList;