import css from "csstype";

const table: css.Properties = {
  border: "1px solid rgb(227, 227, 227)",
  margin: "0 0 1rem 0",
  width: "fit-content",
  minWidth: "91vw",
};
const employeTable: css.Properties = {
  border: "1px solid rgb(227, 227, 227)",
  margin: "0 0 1rem 0",
  minWidth: "45.5vw",
  marginBottom: "1rem"
};
const tableHeader: css.Properties = {
  padding: "0.5rem 0.5rem",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  width: "100%",
};
const tableTxt: css.Properties = {
  width: "100%",
  margin: "1rem 0",
};
const tableHeadTxt: css.Properties = {
  margin: "1rem 2rem 1rem 0",
};
const employeeTableHead: css.Properties = {
  width: "100%",
  margin: "1rem 0",
};
const addEmployeeBtn: css.Properties = {
  marginRight: "1rem",
};
const tableContainer: css.Properties = {
  border: "1px solid rgb(227, 227, 227)",
  margin: "0 0 1rem 0",
  width: "93vw",
}
const buttonBack: css.Properties = {
  width: "3rem",
  height: "2rem",
  marginBottom: "0.5rem"
}
const tableFilterContainer: css.Properties = {
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  margin: ".5rem 0 .5rem 0"
}
const tableFilter: css.Properties = {
  width: "12rem"
}
const tableWithFiltersHeader: css.Properties = {
  padding: "0 0.5rem",
  display: "flex",
  flexWrap: "wrap"
};
const filtersContainer: css.Properties = {
  display: "flex",
  gap: "1rem",
  alignItems: "end",
  margin: "0",
  flexWrap: "wrap"
};
const filterButton: css.Properties = {
  height: "2rem"
};
const tableHeaderWrapper: css.Properties = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between"
};
const assignmentsTableHeader: css.Properties = {
  padding: "0.5rem 0.5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  maxWidth: "100%",
}
const addAssignmentButton: css.Properties = {
  maxHeight: "2rem",
  alignSelf: "center"
}
const employeesCard: css.Properties = {
  marginBottom: "2rem"
}
const styles = {
  table,
  tableHeader,
  tableTxt,
  addEmployeeBtn,
  tableHeadTxt,
  tableWithFiltersHeader,
  employeTable,
  employeeTableHead,
  tableContainer,
  buttonBack,
  tableFilterContainer,
  tableFilter,
  filtersContainer,
  filterButton,
  tableHeaderWrapper,
  assignmentsTableHeader,
  addAssignmentButton,
  employeesCard
};

export default styles;
