import {
  Input,
  InputProps,
  Label,
  makeStyles,
  useId,
} from "@fluentui/react-components";

interface IAppDateSelect {
  label: string;
  formHandler: any;
  currentDate?: string;
}

const useStyles = makeStyles({
  input: {
    width: "150px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
});

export default function AppDateSelect(props: IAppDateSelect & InputProps) {
  const styles = useStyles();
  const { label, formHandler, ...rest } = props;
  const inputId = useId("date-input");
  return (
    <div className={styles.container}>
      <Label htmlFor={inputId}>{label}</Label>
      <Input
        type="date"
        min={props.currentDate}
        className={styles.input}
        {...formHandler}
        {...rest}
      />
    </div>
  );
}
