import { makeStyles } from "@fluentui/react-components";
import { AppLink } from "../common";
import LinkMenu from "./LinkMenu";
import { useContext } from "react";
import UserContext from "../../modules/AppRouter/UserContext";

const useStyles = makeStyles({
  container: {
    paddingInline: "3.5% 3.5%",
    position: "absolute",
    top: "0",
    backgroundColor: "white",
    width: "93%",
    zIndex: "9",
  },
});

const AdminNavbar: React.FC = () => {
  const styles = useStyles();
  const userData = useContext(UserContext);

  return (
    <>
      {userData.isAssigned && (
        <div className={styles.container}>
          {userData.data.role === "Admin" ? (
            <LinkMenu>
              <AppLink to="worktime" text="Czas pracy" />
              <AppLink to="admin/manage-employees" text="Pracownicy" />
              <AppLink to="admin/manage-assigments" text="Zlecenia" />
              <AppLink to="admin/raports" text="Raporty" />
              <AppLink to="admin/registered-time-history" text="Historia czasu pracy"/>
              <AppLink
                to="collected-time-history"
                text="Historia odebranych nadgodzin"
              />
              <AppLink to="leavings-history" text="Historia wyjść" />
              <AppLink to="quick-start" text="Instrukcja" />
            </LinkMenu>
          ) : (
            <LinkMenu>
              <AppLink to="worktime" text="Czas pracy" />
              <AppLink to="employee" text="Nadgodziny" />
            </LinkMenu>
          )}
        </div>
      )}
    </>
  );
};

export default AdminNavbar;
