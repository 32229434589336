import { Link, makeStyles } from '@fluentui/react-components';
import React from 'react';
import { SubtractCircleRegular } from "@fluentui/react-icons";

interface IProps {
  links: string[], 
  removeLink: (index: number) => void
}

const useStyles = makeStyles({
  listItem: {
    paddingInline: '0 1rem',
  },
  substractButton: {
    height: '1.3rem',
    width: '1.3rem',
    marginRight: '0.7rem'
  },
  taskLink: {
    display: 'flex',
    paddingBottom: '0.3rem'
  },
  linkItem: {
    maxWidth: '30rem',
    overflowWrap: 'break-word'
  },
});

const ListItem: React.FC<IProps> = ({ links, removeLink }) => {
  const styles = useStyles();

  return (
    <ul className={styles.listItem}>
      {links &&
        links.map((e, index) => {
          return (
            <li className={styles.taskLink} key={index}>
              <SubtractCircleRegular 
                className={styles.substractButton} 
                onClick={() => removeLink(index)}
              />
              <Link className={styles.linkItem} appearance="subtle" target='_blank' href={e}>
                {e}
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

export default ListItem;
