import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components'
import React from 'react'
import AppButton from './AppButton'

interface IAppDialogProps {
    title: string,
    bodyTxt: string,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    open: boolean,
    onAccept: () => void,
    isLoading?: boolean 
}

const AppConfirmdialog: React.FC<IAppDialogProps> = ({
    title,
    bodyTxt,
    setOpen,
    open,
    onAccept,
    isLoading
}) => {
  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {bodyTxt}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Anuluj</Button>
              </DialogTrigger>
              <AppButton
                text="Tak"
                disabled={isLoading}
                appearance="primary"
                onClick={onAccept}
              />
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
  )
}

export default AppConfirmdialog
