import styles from "./EmplyeesListStyles";
import {
  useScrollbarWidth,
  useFluent,
  Button,
  makeStyles,
} from "@fluentui/react-components";
import * as React from "react";
import { FixedSizeList as List } from "react-window"; 
import {
  TableBody,
  TableRow,
  Table,
  TableHeaderCell,
  createTableColumn,
  useTableFeatures,
  useTableSelection,
  TableRowData as RowStateBase,
  TableRowId,
} from "@fluentui/react-components/unstable";
import { AssignmentListRow } from "../businessComponenets/";
import InfiniteLoader from "react-window-infinite-loader";
import {
  IAssignmentsTabProps,
  IAssignmentsTabRow,
} from "../../services/types/table";
import { useHistory } from "react-router-dom";
import { AppLoader} from "../common";
import { useEffect, useState } from "react";
import { AssignmentsFilters } from "../layouts";
import { IFiltersData, IGetAssignmentsOptions } from "../../services/types/overtimeAssigments";
import { localStorageAccessorEnum } from "../../utils/enums";
import { overtimeAssigments } from "../../services";

interface TableRowData extends RowStateBase<IAssignmentsTabRow> {
  onClick: (e: React.MouseEvent) => void;
  selected: boolean;
  appearance: "brand" | "none";
  getAssignments: () => void
}

const columns = [
  createTableColumn<IAssignmentsTabRow>({
    columnId: "userName",
  }),
  createTableColumn<IAssignmentsTabRow>({
    columnId: "timeToCollection",
  }),
  createTableColumn<IAssignmentsTabRow>({
    columnId: "assignmentDate",
  }),
  createTableColumn<IAssignmentsTabRow>({
    columnId: "status",
  }),
  createTableColumn<IAssignmentsTabRow>({
    columnId: "createDate",
  }),
];

const useStyles = makeStyles({
  tableCell: {
    minWidth: "126px"
  },
})

const AssignmentsList: React.FC<IAssignmentsTabProps> = ({
  tableHeadTxt,
  items,
  getMoreUsers,
  isLoading,
  setOpendialog,
}) => {
  const filters = overtimeAssigments.getStoredFilters();

  const { targetDocument } = useFluent();
  const history = useHistory();
  const scrollbarWidth = useScrollbarWidth({targetDocument});
  const [filtersData, setFiltersData] = useState<IFiltersData>(filters);
  const classes = useStyles();

  const {
    getRows,
    selection: { toggleRow, isRowSelected },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSelection({
        selectionMode: "multiselect",
      }),
    ]
  );

  const updateFilters = (filters: IFiltersData) => {
    localStorage.setItem(localStorageAccessorEnum.Filters, JSON.stringify(filters));
    setFiltersData(filters);
  }

  const showDetails = (e: React.MouseEvent, rowId: TableRowId) => {
    const allRows = getRows();
    const item = allRows.find((e) => {
      return e.rowId === rowId;
    });
    const userId = item?.item.id;

    history.push(`/assignment-details/${userId}/${rowId}`);
  };

  const rows: TableRowData[] = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      onClick: (e: React.MouseEvent) => {
        showDetails(e, row.rowId);
      },
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row.rowId);
        }
      },
      selected,
      appearance: selected ? ("brand" as const) : ("none" as const),
      getAssignments: () => {
        items.length = 0;
        const assignmentOptions: IGetAssignmentsOptions = {
          stopIndex: 0,
          reload: false,
          filters: filtersData
        }

        getMoreUsers(assignmentOptions);
      }
    };
  });

  const isItemLoaded = () => {
    return false;
  };

  const loadMoreItems = (_: number, stopIndex: number) => {
    const assignmentOptions: IGetAssignmentsOptions = {
      stopIndex: stopIndex,
      reload: false,
      filters: filtersData
    }
    getMoreUsers(assignmentOptions);
  };

  useEffect(() => {
    const assignmentOptions: IGetAssignmentsOptions = {
      stopIndex: 0,
      reload: true,
      filters: filtersData
    }
    getMoreUsers(assignmentOptions);
  }, [filtersData]);
  
  return (
    <div>
      <div>
        <div style={styles.assignmentsTableHeader}>
          <div style={styles.tableHeaderWrapper}>
            <h3 style={styles.tableHeadTxt}>{tableHeadTxt}</h3>
              <Button appearance="primary" onClick={() => setOpendialog(true)} style={styles.addAssignmentButton}>
                Dodaj
              </Button>
          </div>
          <AssignmentsFilters 
            setFiltersData={updateFilters}
          />
        </div>
      </div>
      <div className="main-table-container">
        <Table
          noNativeElements
          aria-label="Table with selection"
          aria-rowcount={rows.length}
          style={styles.table}
        >
          <TableBody className="tableBody">
            <TableRow aria-rowindex={1}>
              <TableHeaderCell className={classes.tableCell}>Nazwisko i imię</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Ilość godzin</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Data zlecenia</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Status</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Typ</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Rodzaj</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Data utworzenia</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}/>
              <div
                role="presentation"
                style={{
                  width: scrollbarWidth,
                }}
              />
            </TableRow>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={items.length}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={350}
                  itemCount={items.length}
                  itemSize={45}
                  width="100%"
                  itemData={rows}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {AssignmentListRow}
                </List>
              )}
            </InfiniteLoader>
            {isLoading && <AppLoader />}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default AssignmentsList;
