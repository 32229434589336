import React, { useContext } from 'react'
import PageTitle from '../../components/common/PageTitle'
import { CenteredLayout, CollectedTimeHistoryList} from '../../components/layouts'
import AdminNavbar from '../../components/layouts/AdminNavbar'
import useCollectedTime from '../../hooks/useCollectedTime'
import { userRoleEnum } from '../../utils/enums'
import UserContext from '../AppRouter/UserContext'

const CollectedTimeHistory: React.FC = () => {
   const { collectedTime, getCollectedTime, isLoading } = useCollectedTime()
   const userData = useContext(UserContext);

   return (
      <>
      {userData.data.role === userRoleEnum.Admin &&
         <AdminNavbar/>
      }
         <CenteredLayout>
            <PageTitle title="Historia odebranych nadgodzin" />
            <CollectedTimeHistoryList 
               tableHeadTxt='Historia odebranych nadgodzin'
               isLoading={isLoading}
               getMoreItems={getCollectedTime}
               items = {collectedTime}
            />
         </CenteredLayout>
      </>
  )
}

export default CollectedTimeHistory
