import styles from './EmplyeesListStyles'
import { Body1, Button, Select, useFluent, useId, useScrollbarWidth } from '@fluentui/react-components'
import { 
    Card,
    createTableColumn, 
    Table, 
    TableBody, 
    TableHeader, 
    TableHeaderCell, 
    TableRow, 
    TableRowData as RowStateBase, 
    useTableFeatures, 
    useTableSelection 
} from '@fluentui/react-components/unstable'
import { FixedSizeList as List } from "react-window"; 
import React, { useContext, useEffect, useState } from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import UserContext from '../../modules/AppRouter/UserContext'
import { ICollectedTimeSummary, ICollectedTimeTabProps } from '../../services/types/table'
import { AppLoader } from '../common'
import { CollectedTimeListRow } from '../businessComponenets';
import { userRoleEnum } from '../../utils/enums';
import { usersService } from '../../services';
import { IUserSelectable } from '../../services/types/users';

interface TableRowData extends RowStateBase<ICollectedTimeSummary> {
  getCollectedTime: () => void;
    selected: boolean;
    appearance: "brand" | "none";
  }

const columns = [
    createTableColumn<ICollectedTimeSummary>({
      columnId: "userName",
    }),
    createTableColumn<ICollectedTimeSummary>({
      columnId: "collectedTime",
    }),
    createTableColumn<ICollectedTimeSummary>({
      columnId: "collectionDate",
    }),
  ];

const CollectedTimeHistoryList: React.FC<ICollectedTimeTabProps> = ({  
    tableHeadTxt,
    items,
    isLoading,
    getMoreItems
}) => {
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({
        targetDocument,
    });
    const {
        getRows,
        selection: { toggleRow, isRowSelected },
    } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSelection({selectionMode: "multiselect",}),
    ]
  );
  const userData = useContext(UserContext);
  const selectId = useId();
  const [userOptions, setUserOptions] = useState<IUserSelectable[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>();
  const isItemLoaded = () => false;

  const loadMoreItems = (_: number, stopIndex: number) => {
    loadItems(stopIndex);
  };

  const loadItems = (stopIndex: number) => {
    if (selectedUser) getMoreItems(stopIndex, selectedUser);
    else getMoreItems(stopIndex);
  }

  const rows: TableRowData[] = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      getCollectedTime: () => {
        items.length = 0;
        loadItems(0);
      },
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row.rowId);
        }
      },
      selected,
      appearance: selected ? ("brand" as const) : ("none" as const),
    };
  });

  const goBack = () => {
    window.history.back();
  }
  const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    setSelectedUser(id);
  }

  useEffect(() => {
    if(userData.data.role === userRoleEnum.Admin){
      usersService.selectableUsers()
      .then((users) => {
        setUserOptions(users);
      })
    }
  }, [])

  useEffect(() => {
    if(selectedUser){
      items.length = 0;
      getMoreItems(0, selectedUser);
    }
  }, [selectedUser]);
  return (
    <Card style={styles.tableContainer}>
        <Body1>
            <Button 
              appearance="primary"
              onClick={goBack}
              style={styles.buttonBack}
            >
              Powrót
            </Button>

            
            <div style={styles.tableWithFiltersHeader}>
                <h3 style={styles.tableHeadTxt}>{tableHeadTxt}</h3>
                {userData.data.role === userRoleEnum.Admin &&
                <div style={styles.tableFilterContainer}>
                    Pracownik:
                    <Select id={selectId} style={styles.tableFilter} onChange={onSelect}>
                      <option>Wszyscy</option>
                      {userOptions && userOptions.map((e) => {
                        return(
                          <option key={e.id} value={e.id}>{`${e.lastName} ${e.firstName}`}</option>
                        )
                      })}
                    </Select>
                  </div>}
            </div>
            <div className='main-table-container'>
              <Table
                  noNativeElements
                  aria-label="Table with selection"
                  aria-rowcount={rows.length}
                  style={styles.table}
              >
              <TableHeader>
                <TableRow aria-rowindex={1}>
                    <TableHeaderCell>
                      Nazwisko i imię
                    </TableHeaderCell>
                    <TableHeaderCell>Ilość godzin</TableHeaderCell>
                    <TableHeaderCell>Data odebrania</TableHeaderCell>
                    {userData.data.role === userRoleEnum.Admin && 
                    <TableHeaderCell/>}
                    <div
                    role="presentation"
                    style={{
                        width: scrollbarWidth,
                    }}
                />
                </TableRow>
              </TableHeader>
              <TableBody>
              <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={items.length}
                  loadMoreItems={loadMoreItems}
              >
                  {({ onItemsRendered, ref }) => (
                  <List
                      height={350}
                      itemCount={items.length}
                      itemSize={45}
                      width="100%"
                      itemData={rows}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                  >
                      {CollectedTimeListRow}
                  </List>
                  )}
              </InfiniteLoader>
              {isLoading && <AppLoader />}
              </TableBody>
          </Table>
        </div>
    </Body1>
  </Card>
  )
}

export default CollectedTimeHistoryList
