import moment from "moment";

const defaultDateFormat = "YYYY-MM-DD";

class DateFormatService {
  formatDate(date: string) {
    return moment(date).format(defaultDateFormat);
  }

  formatDateToTime(date: string) {
    if (moment(date, "DD.MM.YYYY HH:mm:ss", true).isValid()) {
        return moment(date, "DD.MM.YYYY HH:mm:ss").format("HH:mm:ss");
    }
     
    return moment(date).format("HH:mm");
  }
}

const dateFormatService = new DateFormatService();
export default dateFormatService;
