import { applicationTypeEnum, assignmentStatus, overtimeTypeEnum } from '../utils/enums';

class TranslateService {
  translateType(type: string | undefined) {
    switch (type) {
      case overtimeTypeEnum.TimeToPay:
        return 'Do wypłaty';
      case overtimeTypeEnum.TimetoCollection:
        return 'Do odbioru';
      default:
        return 'Not found';
    }
  }
  translateStatus(status: string | undefined) {
    switch (status) {
      case assignmentStatus.New:
        return 'Nowe';
      case assignmentStatus.Accepted:
        return 'Zaakceptowane';
      case assignmentStatus.Realized:
        return 'Zrealizowane';
      case assignmentStatus.NotRealized:
        return 'Niezrealizowane';
      case assignmentStatus.Rejected:
        return 'Odrzucone';
      case assignmentStatus.PartiallyRealized:
        return 'Częściowo zrealizowane';
      default:
        return 'Not found';
    }
  }
  translateAppplicationType(type: string | undefined) {
    switch (type) {
      case applicationTypeEnum.Leaving:
        return 'Wyjście';
      case applicationTypeEnum.Overtime:
        return 'Nadgodziny';
      default:
        return 'Not found';
    }
  }
}

const translateService = new TranslateService();
export default translateService;
