import AppRouter from "./modules/AppRouter/AppRouter";
import { AppToaster } from "./components/common/";
import {
  FluentProvider,
  teamsLightTheme as theme,
} from "@fluentui/react-components";
import "./styles.scss";

export default function App() {
  return (
    <FluentProvider theme={theme}>
      <AppRouter />
      <AppToaster />
    </FluentProvider>
  );
}
