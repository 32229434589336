import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
  makeStyles,
  Textarea,
  TextareaProps,
  tokens,
  useId,
} from "@fluentui/react-components";
import { Dispatch, SetStateAction, useState } from "react";
import { AppButton } from "../common";

const useStyles = makeStyles({
  base: {
    display: "flex",
    flexDirection: "column",
    "& > label": {
      marginBottom: tokens.spacingVerticalMNudge,
    },
  },
});

const RejectAssignmentDialog = ({
  open,
  setOpen,
  rejectAssignment,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  rejectAssignment: (rejectionDescription: string, setIsRejecting: Dispatch<SetStateAction<boolean>>) => void;
}) => {
  const textareaId = useId("textarea");
  const styles = useStyles();
  const [description, setDescription] = useState("");
  const [isRejecting, setIsRejecting] = useState(false);

  const onChange: TextareaProps["onChange"] = (_, data) => {
    if (data.value.length <= 250) {
      setDescription(data.value);
    }
  };
  const reject = () => {
    rejectAssignment(description, setIsRejecting);
    setDescription("");
  };
  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Odrzuć wniosek/zlecenie</DialogTitle>
          <DialogContent className={styles.base}>
            <Label htmlFor={textareaId}>Powód odrzucenia</Label>
            <Textarea id={textareaId} value={description} onChange={onChange} />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Anuluj</Button>
            </DialogTrigger>
            <AppButton
                text="Odrzuć"
                disabled={isRejecting}
                appearance="primary"
                onClick={reject}
              />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default RejectAssignmentDialog;
