import { useState, useEffect, Dispatch, SetStateAction, useContext } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  makeStyles,
  Checkbox,
} from "@fluentui/react-components";
import {
  AppButton,
  AppUserSelect,
  AppDateSelect,
  AppDivider,
  AppTimeInput,
  AppSelect,
  AppTextArea,
} from "../common";
import { useForm } from "react-hook-form";
import usersService from "../../services/users.service";
import dataTimeService from "../../services/dataTime.service";
import OvertimeAssigments from "../../services/overtimeAssigments.service";
import { IUserSelectable } from "../../services/types/users";
import { applicationTypeEnum, overtimeTypeEnum, userRoleEnum } from "../../utils/enums";
import { IAssignmentsTabRow } from "../../services/types/table";
import appErrorToast from "../common/AppErrorToast";
import { IGetAssignmentsOptions } from "../../services/types/overtimeAssigments";
import UserContext from "../../modules/AppRouter/UserContext";

interface IFormData {
  employeeId: string;
  assigmentDate: Date;
  duration: string;
  type: "Wypłata" | "Do odbioru" | null;
  applicationType: "Nadgodziny" | "Wyjście" | null,
  assignmentDescription: string;
  generateApplication: boolean;
}
interface IDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  getAssignments: (options: IGetAssignmentsOptions) => void;
  setAssignments: Dispatch<React.SetStateAction<IAssignmentsTabRow[]>>;
}

const useStyles = makeStyles({
  dialogContainer: {
    maxWidth: "450px",
  }
});

const AddAssignmentDialog: React.FC<IDialogProps> = ({
  open,
  setOpen,
  getAssignments,
  setAssignments,
}) => {
  const styles = useStyles();
  const { register, handleSubmit, reset, watch, setValue, control, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useContext(UserContext);
  const applicationTypeFieldValue = watch('applicationType');

  const [userOptions, setUserOptions] = useState<IUserSelectable[]>([]);

  useEffect(() => {
    usersService.selectableUsers().then((users) => {
      if (userData.data.role === userRoleEnum.Employee) {
        users = users.filter(x => x.id === userData.data.id);
      }
      setUserOptions(users);
    });
  }, []);

  async function handleFormSubmit(data: IFormData) {
    setIsLoading(true);

    if (dataTimeService.convertTimeToMin(data.duration) < 1) {
      appErrorToast("Ustaw prawidłową liczbę nadgodzin");
      setIsLoading(false);
      return;
    }

    await OvertimeAssigments.addAssigment({
      employeeId: data.employeeId,
      assignmentDate: data.assigmentDate,
      duration: dataTimeService.convertTimeToMin(data.duration),
      type:
        data.type === "Wypłata"
          ? overtimeTypeEnum.TimeToPay
          : overtimeTypeEnum.TimetoCollection,
      applicationType: 
          data.applicationType === "Wyjście"
          ? applicationTypeEnum.Leaving
          : applicationTypeEnum.Overtime,
      assignmentDescription: data.assignmentDescription,
      generateApplication: data.generateApplication
    });
    reset();
    setIsLoading(false);
    setOpen(false);
    setAssignments([]);
    getAssignments({stopIndex: 0, reload: true});
  }
  const dialogOpenChange = () => {
    reset();
    setOpen(false);
  };
  const handleApplicationTypeChanges = () => {
    if (applicationTypeFieldValue === "Nadgodziny") {
      setValue("generateApplication", false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={dialogOpenChange}>
      <DialogSurface className={styles.dialogContainer}>
        <form>
          <DialogBody>
            <DialogTitle>{userData.data.role === userRoleEnum.Admin ? "Formularz zlecenia nadgodzin" : "Wniosek o nadgodziny/wyjście"}</DialogTitle>
            <DialogContent>
              <AppDivider />
              <AppUserSelect
                label="Wybierz pracownika"
                options={userOptions}
                formHandler={register("employeeId", {
                  required: true,
                })}
              />
              <AppDivider />
              <AppDateSelect
                label="Wybierz datę"
                formHandler={register("assigmentDate", {
                  required: true,
                })}
                defaultValue={dataTimeService.todayString()}
              />
              <AppDivider />
              <AppTimeInput
                type="time"
                label="Liczba godzin"
                formHandler={register("duration", {
                  required: {value: true, message: "Liczba godzin jest wymagana"},
                  min: 1,
                })}
                defaultValue="01:00"
                control={control}
                errors={errors}
              />
              {userData.data.role === userRoleEnum.Admin &&
              <>
                <AppDivider />
                <AppSelect
                  label="Typ"
                  options={["Wypłata", "Do odbioru"]}
                  formHandler={register("type", { required: true })}
                />
              </>}
              {userData.data.role === userRoleEnum.Employee &&
              <>
                <AppDivider />
                <AppSelect
                  label="Typ"
                  options={["Nadgodziny", "Wyjście"]}
                  formHandler={register("applicationType", { required: true })}
                  onClick={handleApplicationTypeChanges}
                />
                {applicationTypeFieldValue === "Wyjście" &&
                <>
                  <AppDivider />
                  <Checkbox
                    label="Odrabiam tego samego dnia"
                    {...register("generateApplication")}
                  />
                </>}
              </>}
              <AppDivider />
              <AppTextArea 
                label="Opis"
                formHandler={register("assignmentDescription", {maxLength: 1000})}
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <AppButton text="Anuluj" appearance="secondary" />
              </DialogTrigger>
              <AppButton
                text="Dodaj"
                disabled={isLoading}
                appearance="primary"
                onClick={handleSubmit((data) => {
                  handleFormSubmit(data as IFormData);
                })}
              />
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};

export default AddAssignmentDialog;
