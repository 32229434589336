import { Button, makeStyles, Tooltip } from '@fluentui/react-components';
import {
  TableCell,
  TableCellLayout,
  TableRow,
  TableRowData as RowStateBase,
} from '@fluentui/react-components/unstable';
import { ListChildComponentProps } from 'react-window';
import dateFormatService from '../../services/dateFormat.service';
import { IModificationLogDetails, IWorktimeWithinDayTabRow } from '../../services/types/table';
import { EditRegular } from "@fluentui/react-icons";
import { BookExclamationMarkRegular } from "@fluentui/react-icons";
import { useState } from 'react';
import { worktimeHistoryService } from '../../services';
import WorktimeEditDialog  from '../common/Worktime/WorktimeEditDialog';
import LoggedModificationDialog from '../common/Worktime/LoggedModificationDialog';
import moment from 'moment';

interface ReactWindowRenderFnProps extends ListChildComponentProps {
  data: TableRowData[];
  reload: () => void;
}

interface TableRowData extends RowStateBase<IWorktimeWithinDayTabRow> {}

const useStyles = makeStyles({
  tableCell: {
    minWidth: "126px"
  },
})

const WorktimeWithinDayListRow = ({
  index,
  style,
  data,
  reload
}: ReactWindowRenderFnProps) => {

  const { item } = data[index];
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openLogsDialog, setOpenLogsDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [reasonForEdit, setReasonForEdit] = useState('');
  const [logDetails, setLogDetails] = useState<IModificationLogDetails[]>([{ 
    worktimeBeginningBefore: "", 
    worktimeFinishBefore: "", 
    reasonForEdit: "", 
    fullname: "", 
    modifiedAt: "" 
  }]);
  const [logIsLoading, setLogIsLoading] = useState(false);

  const editWorktime = () => {
    setIsLoading(true);
    worktimeHistoryService.editWorktime(item.id, startTime, endTime, reasonForEdit)
      .then(() => {
        reload();
        setIsLoading(false);
        setOpenDialog(false);
      })
      .catch((error) => {
        setIsLoading(false);
      })
  }

  const handleEditButonClick = (worktimeStart: string, worktimeEnd: string) => {
    setStartTime(moment(worktimeStart).format('HH:mm'));
    setEndTime(moment(worktimeEnd).format('HH:mm'));
    setOpenDialog(true);
  };

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
  };

  const handleModificationLogButtonClick = () => {
    setLogIsLoading(true);
    setOpenLogsDialog(true);
    
    worktimeHistoryService.getModifiedWorktime(item.id)
      .then((response) => {
        setLogDetails(response);
        setLogIsLoading(false);
      })
  }

  return (
    <>
      <TableRow
        aria-rowindex={index + 2}
        style={style}
        key={item.id}        
      >
        <TableCell className={classes.tableCell}>
          <TableCellLayout>{
            dateFormatService.formatDateToTime(item?.worktimeBeginning)
          }</TableCellLayout>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <TableCellLayout>{
            dateFormatService.formatDateToTime(item?.worktimeFinish)
          }</TableCellLayout>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <TableCellLayout>{
            item?.worktimeSpan
          }</TableCellLayout>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <TableCellLayout>
            <Button 
              icon={<EditRegular/>} 
              onClick={() => handleEditButonClick(item?.worktimeBeginning, item?.worktimeFinish)} 
              style={{marginRight: '1em'}}
            />
            {item?.reasonForEdit && (
              <Tooltip content={item.reasonForEdit} relationship="label">
                <Button 
                  icon={<BookExclamationMarkRegular />}
                  onClick={() => handleModificationLogButtonClick()} 
                />
              </Tooltip>
            )}
          </TableCellLayout>
        </TableCell>

        <WorktimeEditDialog
          title='Edytuj czasu pracy'
          setOpen={setOpenDialog}
          open={openDialog}
          onAccept={editWorktime}
          isLoading={isLoading}
          startWorktime={startTime}
          endWorktime={endTime}
          setReasonForEdit={setReasonForEdit}              
          handleEndTimeChange={handleEndTimeChange}
          handleStartTimeChange={handleStartTimeChange}
        />

        <LoggedModificationDialog
          title='Sczegóły zarejestrowanych modyfikacji czasu pracy'
          content={logDetails}
          setOpen={setOpenLogsDialog}
          open={openLogsDialog}
          isLoading={logIsLoading}
        />
      </TableRow>
    </>
  );
};

export default WorktimeWithinDayListRow;
