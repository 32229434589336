import { useCallback, useEffect, useState } from "react";
import { ICollectedTimeSummary } from "../services/types/table";
import { overtimeSummariesService } from "../services";

const loadRows = 10;

export default function useCollectedTime() {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(loadRows);
  const [collectedTime, setCollectedTime] = useState<ICollectedTimeSummary[]>([]);

  const getCollectedTime = useCallback(
    async (stopIndex: number, userId?: string, reload?: boolean) => {
      if (
        (!isLoading &&
          collectedTime.length - loadRows <= stopIndex &&
          total >= collectedTime.length) ||
        reload
      ) {
        setIsLoading(true);

        if (reload) {
          collectedTime.length = 0;
        }

        const newCollectedTime = await overtimeSummariesService.getCollectedTime(
          collectedTime.length,
          userId
        );
        setCollectedTime(prevCollectedTime => [...prevCollectedTime, ...newCollectedTime.data]);
        setTotal(newCollectedTime.total);
        setIsLoading(false);
      }
      return { collectedTime };
    },
    [collectedTime, setCollectedTime, setIsLoading]
  );

  useEffect(() => {
    getCollectedTime(0);
  }, []);

  return {
    getCollectedTime,
    collectedTime,
    isLoading,
    setCollectedTime,
  };
}
