import {
  TableCell,
  TableCellLayout,
  TableRow,
  TableRowData as RowStateBase,
} from '@fluentui/react-components/unstable';
import { ListChildComponentProps } from 'react-window';
import dataTimeService from '../../services/dataTime.service';
import dateFormatService from '../../services/dateFormat.service';
import { ICollectedTimeSummary } from '../../services/types/table';

interface ReactWindowRenderFnProps extends ListChildComponentProps {
  data: TableRowData[];
}
interface TableRowData extends RowStateBase<ICollectedTimeSummary> {
  getCollectedTime: () => void;
  selected: boolean;
  appearance: 'brand' | 'none';
}

const LeavingsListRow = ({
  index,
  style,
  data,
}: ReactWindowRenderFnProps) => {
  const { item, appearance } = data[index];

  return (
    <TableRow
      aria-rowindex={index + 2}
      style={style}
      appearance={appearance}
      key={item.id}
    >
      <TableCell>
        <TableCellLayout>{`${item?.employee?.lastName} ${item?.employee?.firstName}`}</TableCellLayout>
      </TableCell>
      <TableCell>
        <TableCellLayout>{`${dataTimeService.convertToTimeFormat(item.collectedTime)}`}</TableCellLayout>
      </TableCell>
      <TableCell>
        <TableCellLayout>{`${dateFormatService.formatDate(item.date)}`}</TableCellLayout>
      </TableCell>
    </TableRow>
  );
};

export default LeavingsListRow;
