import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "100%",
  },
});

export default function LandingPageLayout(props: {
  children: React.ReactNode;
}) {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
}
