import { makeStyles } from "@fluentui/react-components";
import { Background } from "../common";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
});

export default function CenteredLayout(props: { children: React.ReactNode }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Background />
      {props.children}
    </div>
  );
}
