import { useState } from "react";
import { IWorktimeHistoryTabRow, IWorktimeWithinDayTabRow } from "../services/types/table";
import { worktimeHistoryService } from "../services";
import { 
  IGetWorktimeWithinDayOptions, 
  IGetWorktimeHistoryOptions, 
} from "../services/types/worktimeHistory";

const loadRows = 10;

export default function useWorktimeHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(loadRows);
  const [worktimes, setWorktimes] = useState<IWorktimeHistoryTabRow[]>([]);
  const [withinDayWorktime, setWorktimeWithinDay] = useState<IWorktimeWithinDayTabRow[]>([]);
  const [userWorktimePageData, setUserWorktimePageData] = useState<{UserId: string, Date: string}>({UserId:'' ,Date: ''});

  const getWorktimeHistory = ({ stopIndex, reload, filters }: IGetWorktimeHistoryOptions) => {
    if (
      (!isLoading &&
        worktimes.length - loadRows <= stopIndex &&
        total > worktimes.length) ||
      reload
    ) {
      setIsLoading(true);

      if (reload) {
        worktimes.length = 0;
      }

      worktimeHistoryService.getWorktimeHistory(
        worktimes.length,
        filters
      ).then(response => {
        setWorktimes([...worktimes, ...response.data]);
        setTotal(response.total);
      }).finally(() => {
        setIsLoading(false);
      });
    }

    return { worktimes };
  };

  const getWorktimesWithinDay = ({ stopIndex, reload, id, userId, date }: IGetWorktimeWithinDayOptions) => {
    if (
      (!isLoading &&
        withinDayWorktime.length - loadRows <= stopIndex &&
        total > withinDayWorktime.length) ||
      reload
    ) {
      setIsLoading(true);

      if (reload) {
        withinDayWorktime.length = 0;
      }

      worktimeHistoryService
        .getWorktimesWithinDay(
          withinDayWorktime.length,
          id,
          userId,
          date
        ).then(response => {
          setWorktimeWithinDay([...withinDayWorktime, ...response?.data]);
          setUserWorktimePageData({
            UserId: response?.userId?.toString(), 
            Date: response?.formattedDate?.toString()
          });
          setTotal(response?.total);
        }).finally(() => {
          setIsLoading(false);
        });

        return { withinDayWorktime };
    }
  }

  return {
    getWorktimeHistory,
    worktimes,
    getWorktimesWithinDay,
    withinDayWorktime,
    isLoading,
    setWorktimes,
    userWorktimePageData
  };
}
