import { usersService } from "../../services"
import { AppUserSelect } from "../common"
import { useForm } from "react-hook-form"
import { userRoleEnum } from "../../utils/enums"
import { useContext, useEffect, useState } from "react"
import { IUserSelectable } from "../../services/types/users"
import { IFiltersWithinDay } from "../../services/types/worktimeHistory"
import { Button } from "@fluentui/react-components"
import UserContext from "../../modules/AppRouter/UserContext"
import AppDateInput from "../common/AppDateInput"
import styles from "./EmplyeesListStyles"

interface WorktimeWithinDayFiltersProps {
  initialValues: IFiltersWithinDay;
  getWorktimesWithinDay: (userId?: string, worktimeDate?: string) => void;
}

const WorktimeWithinDayFilters: React.FC<WorktimeWithinDayFiltersProps> = ({ initialValues, getWorktimesWithinDay }) => {
  const { register } = useForm<IFiltersWithinDay>();
  const userData = useContext(UserContext);
  const [userOptions, setUserOptions] = useState<IUserSelectable[]>([]);
  const [employee, setEmployee] = useState<string>();
  const [date, setDate] = useState(initialValues.date);
  const [usingDefaults, setUsingDefaults] = useState(true);

  useEffect(() => {
    usersService.selectableUsers().then((users) => {
      setUserOptions([...users]);
    });
  }, []);

  useEffect(() => {
    if (userOptions.length > 0 && usingDefaults) {
      let selectedEmployee = userOptions.find((user) => user?.id === initialValues?.employeeId);
      setEmployee(selectedEmployee?.id?.toString());
      setDate(initialValues.date);
    }
  }, [initialValues, userOptions, usingDefaults]);

  const handleEmployeeChange = (event: { target: { value: string } }) => {
    setEmployee(event.target.value);
    setUsingDefaults(false);
  };

  const handleDateChange = (event: { target: { value: string } }) => {
    setDate(event.target.value);
    setUsingDefaults(false);
  };

  return (
    <div style={styles.filtersContainer}>
      {
        userData.data.role === userRoleEnum.Admin &&
        <AppUserSelect
          label="Wybierz pracownika"
          options={userOptions}
          formHandler={register("employeeId")}
          value={employee}
          onChange={handleEmployeeChange}
        />
      }
      <AppDateInput
        label="Data"
        formHandler={register("date")}
        value={date}
        onChange={handleDateChange}
      />
      <Button 
        style={styles.filterButton}
        type="submit"
        onClick={() => getWorktimesWithinDay(employee, date)}
      >
        Filtruj
      </Button>
    </div>
  );
};

export default WorktimeWithinDayFilters;