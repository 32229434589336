import {
  makeStyles,
  shorthands,
  Body1,
  Input,
  useId,
  InputProps,
  Label,
  Button,
} from "@fluentui/react-components";
import { Alert, Card } from "@fluentui/react-components/unstable";
import moment from "moment";
import { useEffect, useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { CenteredLayout } from "../../../components/layouts";
import AdminNavbar from "../../../components/layouts/AdminNavbar";
import dataTimeService from "../../../services/dataTime.service";
import { reportsService } from "../../../services";
import { AppButton } from "../../../components/common";

const useStyles = makeStyles({
  card: {
    width: "93vw",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  button: {
    maxHeight: "2rem",
  },
});

const ManageRaports: React.FC = () => {
  const [mobile, setMobile] = useState<boolean>(window.innerWidth <= 719);
  const inputMonthId = useId("inputMonthId");
  const [date, setDate] = useState<string>(dataTimeService.getCurrentMonth());
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const onMonthChange: InputProps["onChange"] = (ev, date) => {
    setDate(date.value);
  };
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  const generateRaport = () => {
    setIsLoading(true);
    const month = dataTimeService.getMonth(date);
    const year = dataTimeService.getYear(date);
    reportsService.generateMonthlyReport(year, month + 1, setIsLoading);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  return (
    <>
      <AdminNavbar />
      <CenteredLayout>
        <PageTitle title="Raporty" />
        <Card className={styles.card}>
          <h3>Generuj raport miesięczny</h3>
          {!mobile &&
            <Body1 className={styles.cardBody}>
              <div className={styles.form}>
                <Label htmlFor={inputMonthId}>Wybierz miesiąc:</Label>
                <Input
                  type="month"
                  max={moment().format("YYYY-MM")}
                  value={date}
                  onChange={onMonthChange}
                  id={inputMonthId}
                />
              </div>
              <AppButton
                text="Generuj raport"
                className={styles.button}
                disabled={isLoading}
                appearance="primary"
                onClick={generateRaport}
              />
            </Body1>
          } 
          {mobile && <Alert intent="info">Opcja nie jest obsługiwana w aplikacji mobilnej</Alert>}                    
        </Card>
      </CenteredLayout>
    </>
  );
};
export default ManageRaports;
