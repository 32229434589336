import { Button } from "@fluentui/react-components"
import { overtimeAssigments, usersService } from "../../services"
import { AppSelect, AppUserSelect } from "../common"
import AppDateInput from "../common/AppDateInput"
import styles from "./EmplyeesListStyles"
import { SubmitHandler, useForm } from "react-hook-form"
import { assignmentStatus, assignmentStatusPl, localStorageAccessorEnum, userRoleEnum } from "../../utils/enums"
import { useContext, useEffect, useState } from "react"
import { IUserSelectable } from "../../services/types/users"
import { IFiltersData } from "../../services/types/overtimeAssigments"
import UserContext from "../../modules/AppRouter/UserContext"


const AssignmentsFilters: React.FC<{setFiltersData: (filters: IFiltersData) => void}> = ({setFiltersData}) => {
    const filters = overtimeAssigments.getStoredFilters();
    
    const { register, handleSubmit, watch, reset} = useForm<IFiltersData>();
    const [userOptions, setUserOptions] = useState<IUserSelectable[]>([]);
    const userData = useContext(UserContext);

    const filterData: SubmitHandler<IFiltersData> = (data) => { 
        let assignmentStatusType: assignmentStatus | undefined;
        switch (data.status){
          case assignmentStatusPl.New:
            assignmentStatusType = assignmentStatus.New;
            break;
          case assignmentStatusPl.Accepted:
            assignmentStatusType = assignmentStatus.Accepted;
            break;
          case assignmentStatusPl.Realized:
            assignmentStatusType = assignmentStatus.Realized;
            break;
          case assignmentStatusPl.Rejected:
            assignmentStatusType = assignmentStatus.Rejected;
            break;
          case assignmentStatusPl.NotRealized:
            assignmentStatusType = assignmentStatus.NotRealized;
            break;
          default:
            assignmentStatusType = undefined;
            break;
        }

        setFiltersData({
          dateFrom: data.dateFrom,
          dateTo: data.dateTo,
          employeeId: data.employeeId,
          status: data.status,
          assignmentStatus: assignmentStatusType
        })
      }

      const clearFilters = () => {
        localStorage.removeItem(localStorageAccessorEnum.Filters);
        reset();
        setFiltersData({
          dateFrom: "",
          dateTo: "",
          employeeId: "",
          assignmentStatus: undefined,
          status: undefined
        });
      }

      useEffect(() => {
        usersService.selectableUsers().then((users) => {
          const defaultValue: IUserSelectable = {id: "", firstName: "Wszyscy", lastName: "" }
          setUserOptions([defaultValue ,...users]);
          reset(filters);
        });
      }, []);

  return (
    <form style={styles.filtersContainer} onSubmit={handleSubmit(filterData)}>
            {userData.data.role === userRoleEnum.Admin &&
            <AppUserSelect
              label="Wybierz pracownika"
              options={userOptions}
              formHandler={register("employeeId")}
            />}
            <AppDateInput 
              label="Data zlecenia od"
              formHandler={register("dateFrom")}
              max={watch("dateTo")}
              defaultValue={filters?.dateFrom}
            />
            <AppDateInput 
              label="Data zlecenia do"
              formHandler={register("dateTo")}
              min={watch("dateFrom")}
              defaultValue={filters?.dateTo}
            />
            <AppSelect 
              label="Status"
              formHandler={register("status")}
              options={["Wszystkie", assignmentStatusPl.Accepted, assignmentStatusPl.New , assignmentStatusPl.Realized , assignmentStatusPl.NotRealized , assignmentStatusPl.Rejected]}
              defaultValue={filters?.status}
            />
            <Button 
              style={styles.filterButton}
              type="submit"
            >
              Filtruj
            </Button>
            <Button 
              style={styles.filterButton}
              type="button"
              onClick={clearFilters}
            >
              Wyczyść filtry
            </Button>
          </form>
  )
}

export default AssignmentsFilters
