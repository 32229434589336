import InAndOutHistory from "../InAndOutHistory/InAndOutHistory";
import "./DesktopWorktimeLayoutStyles.css";
import ActivityStatus from "../../../components/common/ActivityStatus/ActivityStatus";
import { Button } from "@fluentui/react-button";
import { BackOnePage } from "../../../services/Helpers/HelperService";

const DesktopWorktimeLayout = () => {
  return (
    <div className="desktopLayoutContainer">
      <div className="button-container">
        <Button onClick={() => BackOnePage()}>Powrot</Button>
      </div>
      <div className="content">
        <div className="width50">
          <InAndOutHistory />
        </div>
        <div className="width50">
          <ActivityStatus />
        </div>
      </div>
    </div>
  );
};

export default DesktopWorktimeLayout;
