import { Avatar, Button } from "@fluentui/react-components";
import {
  TableCell,
  TableCellLayout,
  TableRow,
  TableSelectionCell,
  TableRowData as RowStateBase,
} from "@fluentui/react-components/unstable";
import { ListChildComponentProps } from "react-window";
import { IUser } from "../../services/types/table";
import { AppConfirmdialog } from "../common";
import { useState } from "react";
import { usersService } from "../../services";

interface ReactWindowRenderFnProps extends ListChildComponentProps {
  data: TableRowData[];
}
interface TableRowData extends RowStateBase<IUser> {
  getUsers: () => void;
  onClick: (e: React.MouseEvent) => void;
  selected: boolean;
  appearance: "brand" | "none";
  assigned: boolean;
}

const UserListRow = ({ index, style, data }: ReactWindowRenderFnProps) => {
  const { item, selected, appearance, getUsers, onClick, assigned } = data[index];
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteUser = () => {
    setIsLoading(true);
    usersService.deleteUser(item.id)
    .then(() => {
      setIsLoading(false);
      setOpenDialog(false);
      getUsers();
    })
  }
  return (
    <TableRow
      aria-rowindex={index + 2}
      style={style}
      onClick={onClick}
      appearance={appearance}
    >
      {!assigned && (
        <TableSelectionCell
          checked={selected}
          checkboxIndicator={{
            "aria-label": "Select row",
          }}
        />
      )}
      <TableCell>
        <TableCellLayout
          media={
            <Avatar
              aria-label={`${item.lastName} ${item.firstName}`}
              name={`${item.lastName} ${item.firstName}`}
            />
          }
        >
          {`${item.lastName} ${item.firstName}`}
        </TableCellLayout>
      </TableCell>
      {assigned &&
      <TableCell>
        <TableCellLayout>
          <Button onClick={() => {setOpenDialog(true)}}>Usuń pracownika</Button>
        </TableCellLayout>
      </TableCell>}
      <AppConfirmdialog
        title='Potwierdź usunięcie pracownika'
        bodyTxt='Czy na pewno chcesz usunąć tego pracownika?'
        setOpen={setOpenDialog}
        open={openDialog}
        onAccept={deleteUser}
        isLoading={isLoading}
      />
    </TableRow>
  );
};

export default UserListRow;
