import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  card: {
    width: "93vw",
    marginTop: "9rem",
    "@media (min-width: 966px)": {
      width: "45.5vw"
    },
  },
  realizationCard: {
    width: "100%",
    marginTop: "2rem",
    "@media (min-width: 966px)": {
      width: "50%",
      marginTop: "9rem"
    },
  },
  cardRow: {
    display: "flex",
    justifyContent: "space-between",
    height: "2.5rem",
  },
  cardBody: {
    width: "100%",
  },
  cardBodyContainer: {
    display: "flex",
    columnGap: "3rem",
  },
  button: {
    maxWidth: "200px",
  },
  listItem: {
    paddingInline: "0 1rem",
    listStyleType: "none",
    marginBlock: "0 0",
  },
  cardsContainer: {
    display: "flex",
    width: "93%",
    columnGap: "2rem",
    flexDirection: "column",
    "@media (min-width: 966px)": {
      flexDirection: "row",
    },
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  assignmentDescription: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  editBtn: {
    cursor: "pointer"
  }
})
export default useStyles;
