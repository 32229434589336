import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'row',
    width: '93vw',
  },
});

export default function LinkMenu(props: { children: React.ReactNode }) {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
}
