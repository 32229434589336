class ApiService {
    setPathParams(prefix: string, params: {key: string, value: string}[]) {
        const searchParams = new URLSearchParams();
        params.forEach(param => {
            searchParams.set(param.key, param.value);
        })

        const newSearch = searchParams.toString();
        const newPath = `${prefix}&${newSearch}`;

        return newPath;
    }
}

const apiService = new ApiService();
export default apiService;