import InAndOutHistory from "../../modules/Shared/InAndOutHistory/InAndOutHistory";
import MobileLayout from "../layouts/MobileLayout/MobileLayout";

const MobileInAndOutPage = () => {
  return (
    <MobileLayout>
      <InAndOutHistory />
    </MobileLayout>
  );
};

export default MobileInAndOutPage;
