import { makeStyles, Subtitle2 } from "@fluentui/react-components";

const useStyles = makeStyles({
  cardRow: {
    display: "flex",
    justifyContent: "space-between",
    height: "2.5rem",
  },
});

const AssignemntCardRow: React.FC<{ txt?: string | null; title: string }> = ({
  txt,
  title,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.cardRow}>
      <Subtitle2>{`${title}:`}</Subtitle2>
      <Subtitle2>{txt}</Subtitle2>
    </div>
  );
};

export default AssignemntCardRow;
