import { Spinner } from "@fluentui/react-components";
import ActivityStatusBar from "./ActivityStatusBar/ActivityStatusBar";
import ActivityStatusList from "./ActivityStatusList/ActivityStatusList";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./ActivityStatusStyles.css";
import { GetActivityStatus } from "../../../services/worktime.service";
import BackButton from "../BackButton";
import { mobileWidth } from "../../../services/Helpers/HelperService";
import useActiveUsers from "../../../hooks/useActiveUsers";

const ActivityStatus = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { activeUsers, setActiveUsers } = useActiveUsers();

  const GetActivity = useCallback(() => {
    GetActivityStatus()
      .then((result) => {
        setActiveUsers(result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    GetActivity();
  }, []);

  const userCounts = useMemo(() => {
    const activeCount = activeUsers.filter((user) => user.isActive).length;
    const inactiveCount = activeUsers.length - activeCount;
    return { activeCount, inactiveCount };
  }, [activeUsers]);

  return (
    <>
      {isLoading ? (
        <Spinner size="huge" className="activity-status-spinner" />
      ) : (
        <>
          <ActivityStatusBar
            availableCount={userCounts.activeCount}
            unavailableCount={userCounts.inactiveCount}
            onSearchTextChange={setSearchText}
          />
          <div className="activity-status-list">
            <ActivityStatusList users={activeUsers} searchText={searchText} />
          </div>
          {window.innerWidth < mobileWidth && <BackButton />}
        </>
      )}
    </>
  );
};

export default ActivityStatus;
