import axiosWithoutHandler from "../utils/axiosWithoutHandler";
import appErrorToast from "../components/common/AppErrorToast";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api`;

class ReportsService {
    generateMonthlyReport(year: number, month: number, setIsLoading: Dispatch<SetStateAction<boolean>>) {
        axiosWithoutHandler
          .get(
            `${pathPrefix}/reports/overtime-realizations?month=${month}&year=${year}`,
            { responseType: "blob" }
          )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `RaportNadgodzin_${moment().format("DDMMYYYYhhmmss")}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsLoading(false);
          })
          .catch(() => {
            appErrorToast(
              "Nie można wygenerować raportu z daty przed utworzeniem organizacji"
            );
            setIsLoading(false);
          });
      }
}

const reportsService = new ReportsService();
export default reportsService;
