import React from 'react'
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';
import { useForm } from 'react-hook-form';
import AppButton from '../AppButton'
import AppTimeInput from '../AppTimeInput';
import AppTextArea from '../AppTextArea';
import './WorktimeEditDialog.css'

interface IAppDialogProps {
    title: string,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    open: boolean,
    onAccept: () => void,
    isLoading?: boolean,
    handleStartTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleEndTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    startWorktime: string,
    endWorktime: string,
    setReasonForEdit: React.Dispatch<React.SetStateAction<string>>
}

const WorktimeEditDialog: React.FC<IAppDialogProps> = ({
    title,
    setOpen,
    open,
    onAccept,
    isLoading,
    handleStartTimeChange,
    handleEndTimeChange,
    startWorktime,
    endWorktime,
    setReasonForEdit,
}) => {

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm();

  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      <DialogSurface>
        <form onSubmit={handleSubmit(onAccept)}>
          <DialogBody>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className="dialog-content">
              <div className="dialog-box">
                <AppTimeInput
                  type='time'
                  label='Początek okresu pracy'
                  className="input-field"
                  value={startWorktime}
                  formHandler={{
                    ...register("worktimeStartTime", {
                      required: { value: true, message: "Czas początkowy jest wymagany" }, 
                      onChange: handleStartTimeChange
                    }),
                  }}
                  max={watch("worktimeEndTime")}
                  control={control}
                  errors={errors}
                />
              </div>
              {errors.worktimeStartTime && 
                <span className='error-message'>
                  {errors.worktimeStartTime.message?.toString()}
                </span>}
              <div className="dialog-box">
                <AppTimeInput
                  type='time'
                  label='Zakończenie okresu pracy'
                  className="input-field"
                  value={endWorktime}
                  formHandler={{
                    ...register("worktimeEndTime", { 
                      required: { value: true, message: "Czas końcowy jest wymagany" },
                      onChange: handleEndTimeChange
                    }),
                  }}
                  min={watch("worktimeStartTime")}
                  control={control}
                  errors={errors}
                />
              </div>
              {errors.worktimeEndTime && 
                <span className='error-message'>
                  {errors.worktimeEndTime.message?.toString()}
                </span>}
              <div className="dialog-box" style={{ textAlign: 'left' }}>
                <AppTextArea
                  label='Powód edycji czasu pracy'
                  className="input-field"
                  size="medium"
                  style={{ width: '100%', marginTop: '0.5rem' }}  
                  formHandler={register("reasonForEditDescription", {
                    onChange: (e) => {setReasonForEdit(e.target.value)},
                    required: "Podanie powodu modyfikacji jest wymagane.",
                    minLength: { value: 5, message: "Wpisz co najmniej 5 znaków" },
                    maxLength: { value: 150, message: "Wpisz nie więcej niż 150 znaków." },
                  })}
                />
              </div>
              {errors.reasonForEditDescription && 
                <span className='error-message'>
                  {errors.reasonForEditDescription?.message?.toString()}
                </span>}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Anuluj</Button>
              </DialogTrigger>
              <AppButton
                text="Potwierdz edycje"
                disabled={isLoading}
                appearance={"primary"}
                type='submit'
              />
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};

export default WorktimeEditDialog;