import moment from "moment";
import appErrorToast from "../components/common/AppErrorToast";

const timeFormat = "HH:mm";
const currentMonthFormat = "YYYY-MM";
const defaultDateFormat = "YYYY-MM-DD";

class DataTimeService {
  convertFormat = (timeMin?: number) => {
    return moment
      .utc(moment.duration(timeMin, "minutes").asMilliseconds())
      .format(timeFormat);
  };
  hoursToMinutes = (time: string) => {
    return moment.duration(time).asMinutes();
  };
  todayString = () => {
    return moment().format(defaultDateFormat);
  };
  dateToString = (date: Date) => {
    return moment(date).format(defaultDateFormat);
  };
  getMonth(date: string) {
    return moment(date).month();
  }
  getYear(date: string) {
    return moment(date).year();
  }
  getCurrentMonth() {
    return moment().format(currentMonthFormat);
  }
  convertTimeToMin = (timeToFormat: string) => {
    return moment.duration(timeToFormat).asMinutes();
  };
  convertDate(date: string) {
    return moment(date).format(defaultDateFormat);
  }
  convertToTimeFormat(timeInMin: number) {
    const hours = Math.abs(parseInt((timeInMin / 60).toString()));
    const remainingMinutes = Math.abs(timeInMin % 60);
    const prefix = timeInMin < 0 ? "-" : "";
    const time = `${prefix}${hours}:${("0" + remainingMinutes).slice(-2)}`;
    return time;
  }
  convertStringToMin(time: string) {
    if (this.isValidTime(time)) {
      const parts = time.split(":");
      const hours = parseInt(parts[0]);
      const minutes = parseInt(parts[1]);
      const totalMinutes = hours * 60 + minutes;
      return totalMinutes;
    } else {
      appErrorToast("Podaj czas w prawidłowym formacie 00:00");
      return;
    }
  }
  isValidTime(timeStr: string) {
    const pattern = /^\d{1,2}:\d{2}$/;
    return pattern.test(timeStr);
  }
  getFirstDayOfMonth() {
    return moment().startOf("month").format(defaultDateFormat);
  }
  convert12to24(time: string): string {
    const parsedTime = moment(time, "hh:mm A", true);

    if (!parsedTime.isValid()) {
      return time;
    }

    const time24 = parsedTime.format("HH:mm");
    return time24;
  }
}
const dataTimeService = new DataTimeService();
export default dataTimeService;
