import { useCallback, useEffect, useState } from "react";
import { ICollectedTimeSummary } from "../services/types/table";
import { overtimeSummariesService } from "../services";

const loadRows = 10;

export default function useLeavings() {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(loadRows);
  const [leavings, setLeavings] = useState<ICollectedTimeSummary[]>([]);

  const getLeavings = useCallback(
    async (stopIndex: number, userId?: string, reload?: boolean) => {
      if (
        (!isLoading &&
          leavings.length - loadRows <= stopIndex &&
          total >= leavings.length) ||
        reload
      ) {
        setIsLoading(true);

        if (reload) {
          leavings.length = 0;
        }
        const newLeavings = await overtimeSummariesService.browseLeavings(
          leavings.length,
          userId
        );
        setLeavings(prevLeavings => [...prevLeavings, ...newLeavings.data]);
        setTotal(newLeavings.total);
        setIsLoading(false);
      }
      return { leavings };
    },
    [leavings, setLeavings, setIsLoading]
  );

  useEffect(() => {
    getLeavings(0);
  }, []);

  return {
    getLeavings,
    leavings,
    isLoading,
    setLeavings,
  };
}
