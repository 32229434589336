import { useContext, useMemo } from "react";
import { AppHeader } from "../../components/common";
import { LandingPageLayout } from "../../components/layouts";
import { userRoleEnum } from "../../utils/enums";
import UserContext from "../AppRouter/UserContext";
import ManageOvertime from "./Subpages/ManageOvertime";
import AdminNavbar from "../../components/layouts/AdminNavbar";

export default function EmployeeLandingPage() {
  const userData = useContext(UserContext);

  const promptMessage = useMemo(() => {
    if (!userData.organizationExist) {
      return "Twoja organizacja nie korzysta z aplikacji \n Skontaktuj się z administratorem Twojej organizacji";
    } else {
      return "Twoja organizacja już istnieje w aplikacji \n Skontaktuj się z administratorem Twojej organizacji";
    }
  }, [userData]);

  return (
    <>
      <LandingPageLayout>
        <AdminNavbar />
        {userData.data.organization &&
        userData.data.role !== userRoleEnum.Admin &&
        userData.isAssigned ? (
          <ManageOvertime />
        ) : (
          <AppHeader text={promptMessage} />
        )}
      </LandingPageLayout>
    </>
  );
}
