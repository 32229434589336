import { Spinner } from "@fluentui/react-components";
import InAndOutCard from "../../../components/common/InAndOut/InAndOutCard";
import { useCallback, useEffect, useState } from "react";
import {
  GetInAndOutAsync,
  WorktimeInAndOut,
} from "../../../services/worktime.service";
import "./InAndOutHistoryStyles.css";
import InAndOutBar from "../../../components/common/InAndOut/InAndOutBar/InAndOutBar";
import dataTimeService from "../../../services/dataTime.service";
import BackButton from "../../../components/common/BackButton";
import { mobileWidth } from "../../../services/Helpers/HelperService";

const InAndOutHistory = () => {
  const [inAndOuts, setInAndOuts] = useState<WorktimeInAndOut[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<string>(dataTimeService.todayString());
  const [totalWorkTime, setTotalWorkTime] = useState<string>("00:00");

  const fetchInAndOut = useCallback((date: Date) => {
    setIsLoading(true);
    GetInAndOutAsync({ requestDate: date })
      .then((response) => {
        setInAndOuts(response.worktimes);
        setTotalWorkTime(response.totalWorkTime);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onDataChanged = useCallback((data: string) => {
    setDate(data);
  }, []);

  const inAndOutsMapped = useCallback(
    () =>
      inAndOuts.map((inAndOut, index) => (
        <InAndOutCard key={`in-out-item-${index}`} inAndOut={inAndOut} />
      )),
    [inAndOuts]
  );

  useEffect(() => {
    fetchInAndOut(new Date(date));
  }, [fetchInAndOut, date]);

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <Spinner size="huge" />
        </div>
      ) : (
        <>
          <InAndOutBar
            onDataChanged={onDataChanged}
            date={date}
            totalWorkTime={totalWorkTime}
          />
          <div className="inAndOutHistoryList">
            {inAndOuts.length === 0 && !isLoading ? (
              <h1 className="inAndOutHistoryInformation">
                Jeszcze nie rozpocząłeś pracy!
              </h1>
            ) : (
              <div className="timeline">{inAndOutsMapped()}</div>
            )}
          </div>
          {window.innerWidth < mobileWidth && <BackButton />}
        </>
      )}
    </>
  );
};

export default InAndOutHistory;
