import { useEffect, useRef, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { UserActivity } from "../services/worktime.service";
import { authentication } from "@microsoft/teams-js";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}`;

const useActiveUsers = () => {
  const connectionRef = useRef<signalR.HubConnection | null>(null);
  const [activeUsers, setActiveUsers] = useState<UserActivity[]>([]);

  useEffect(() => {
    connectionRef.current = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Error)
      .withUrl(`${pathPrefix}/hub/active-users`, {
        accessTokenFactory: async () => await authentication.getAuthToken(),
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    if (!!connectionRef?.current) {
      connectionRef.current.start()
        .then(() => {
          connectionRef.current?.invoke("JoinGroupAsync")
            .catch((err) => console.error("Error while joining group: ", err));
        })
        .catch((err) => console.error("Error while starting connection: ", err));

      connectionRef.current.on(
        "UpdateActiveUsersAsync",
        (users: UserActivity[]) => {
          setActiveUsers(users);
        }
      );
    }

    return () => {
      if (connectionRef.current) {
        connectionRef.current.invoke("LeaveGroupAsync")
          .then(() => {
            connectionRef.current?.stop();
          })
          .catch((error) => {
            console.error("Failed to leave group:", error);
            connectionRef.current?.stop();
          });
      }
    };
  }, []);

  return { activeUsers, setActiveUsers };
};

export default useActiveUsers;
