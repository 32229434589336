import { makeStyles } from '@fluentui/react-components';
import { NavLink } from 'react-router-dom';

interface IAppLink {
  to: string;
  text: string;
}

const useStyles = makeStyles({
  root: {
    paddingBlock: '0.5rem 0.5rem',
    paddingInline: '0.7rem 0.7rem',
    textDecorationLine: 'none',
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
    ':hover': {
      backgroundColor: 'ButtonShadow',
    },
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  active: {
    backgroundColor: 'ButtonShadow',
  },
});

export default function AppLink(props: IAppLink) {
  const classes = useStyles();
  const { to, text } = props;

  return (
    <NavLink
      className={classes.root}
      activeClassName={classes.active}
      to={'/' + to}
    >
      {text}
    </NavLink>
  );
}
