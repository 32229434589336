import { useCallback, useEffect, useState } from 'react';
import { IUser } from '../services/types/table';
import usersService from '../services/users.service';

const loadRows = 10;

export default function useUnassignedUsers(assigned: boolean) {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(loadRows);
  const [unassignedUsers, setUnassignedUsers] = useState<IUser[]>([]);

  const getAssignedUsers = useCallback(
    async (stopIndex: number, assigned: boolean) => {
      if (
        !isLoading &&
        unassignedUsers.length - loadRows <= stopIndex &&
        total >= unassignedUsers.length
      ) {
        setIsLoading(true);
        const users = await usersService.getOrgUsers(
          assigned,
          unassignedUsers.length
        );
        setTotal(users.total);
        setUnassignedUsers([...unassignedUsers, ...users.data]);
        setIsLoading(false);
      }
      return { unassignedUsers };
    },
    [unassignedUsers, setUnassignedUsers, setIsLoading]
  );

  useEffect(() => {
    getAssignedUsers(0, assigned);
  }, []);

  return {
    getAssignedUsers,
    unassignedUsers,
    isLoading,
    setUnassignedUsers,
  };
}
