import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
  makeStyles,
} from "@fluentui/react-components";
import { AddCircle24Regular } from "@fluentui/react-icons";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { dataTimeService, overtimeAssigments } from "../../services";
import appErrorToast from "../common/AppErrorToast";
import { ICreateRealizationRequest } from "../../services/types/overtimeAssigments";
import { useParams } from "react-router-dom";
import { AppButton, AppDateSelect, AppTimeInput, ListItem } from "../common";

interface IRealizeAssignmentDialogProps {
  open: boolean;
  date?: string;
  assignmentDuration?: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
  reload: boolean;
  isCommision: boolean;
  isOvertime: boolean;
}
type Inputs = {
  realizationDate: string;
  duration: string;
};

const useStyles = makeStyles({
  formContaier: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: "1rem",
    width: "100%",
  },
  addLinkInput: {
    display: "flex",
  },
  inputIcon: {
    height: "1rem",
  },
  listItem: {
    paddingInline: "0 1rem",
  },
});

const RealizeAssignmentDialog: React.FC<IRealizeAssignmentDialogProps> = ({
  open,
  setOpen,
  date,
  assignmentDuration,
  setReload,
  reload,
  isCommision,
  isOvertime
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm();
  const styles = useStyles();
  const [links, setLinks] = useState<Array<string>>([]);
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState('');

  const addLinkToArray = () => {
    if (link.length > 0) {
      setLinks([...links, link]);
      setLink('');
    }
  };

  const resetForm = () => {
    setLinks([]);
    setLink('');
    reset({ realizationDate: date, duration: assignmentDuration});
  };

  async function handleFormSubmit (data: Inputs) {
    setIsLoading(true);

    if (links.length < 1 && (isCommision || isOvertime)) {
      appErrorToast("Dodaj linki do zadań");
      setIsLoading(false);
      return;
    }
    const requestBody: ICreateRealizationRequest = {
      realizationDate: data.realizationDate,
      duration: dataTimeService.convertTimeToMin(data.duration),
      rejectionDescription: "",
      urls: links,
      id: id,
    };

    await overtimeAssigments.realizeAssignment(requestBody);

    setOpen(false);
    resetForm();
    setIsLoading(false);
    setReload(!reload);
  };

  const removeLink = (index: number) => {
    const date = getValues("realizationDate");
    const duration = getValues("duration");

    const newArrayOfLinks = [...links.slice(0, index), ...links.slice(index + 1)];
    setLinks(newArrayOfLinks);
    reset({ realizationDate: date, duration: duration});
    setLink('');
  }

  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Rozlicz</DialogTitle>
          <DialogContent>
            <form
              className={styles.formContaier}
            >
              <AppDateSelect
                label="Data realizacji"
                className={styles.input}
                formHandler={register("realizationDate", {
                  required: true,
                })}
                defaultValue={date}
              />
              <AppTimeInput
                className={styles.input}
                label="Czas trwania"
                type="time"
                formHandler={register("duration", {
                  required: {value: true, message: "Czas trwania jest wymagany"},
                  min: 1,
                })}
                control={control}
                errors={errors}
                defaultValue={assignmentDuration}
              />
              {(isOvertime || isCommision) &&
              <>
                <Label>Dodaj link do zadania</Label>
                <div className={styles.addLinkInput}>
                  <Input
                    type="text"
                    className={styles.input}
                    contentAfter={
                      <AddCircle24Regular
                        className={styles.inputIcon}
                        onClick={addLinkToArray}
                      />
                    }
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  ></Input>
                </div>
              </>}
            </form>
            {links.length > 0 && <Label>Linki do zadań</Label>}
            <ListItem 
              links={links}
              removeLink={removeLink} 
            />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={resetForm}>
                Anuluj
              </Button>
            </DialogTrigger>
            <AppButton
              text="Rozlicz"
              disabled={isLoading}
              appearance="primary"
              onClick={handleSubmit((data) => {
                handleFormSubmit(data as Inputs);
              })}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default RealizeAssignmentDialog;
