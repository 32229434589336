import { useState } from "react";
import { IAssignmentsTabRow } from "../services/types/table";
import { overtimeAssigments } from "../services";
import { IGetAssignmentsOptions } from "../services/types/overtimeAssigments";

const loadRows = 10;

export default function useAsignments() {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(loadRows);
  const [assignments, setAssignments] = useState<IAssignmentsTabRow[]>([]);

  const getAssignments = ({stopIndex, reload, filters}: IGetAssignmentsOptions) => {
    if (
      (!isLoading &&
        assignments.length - loadRows <= stopIndex &&
        total > assignments.length) ||
      reload
    ) {
      setIsLoading(true);

      if (reload) {
        assignments.length = 0;
      }

      overtimeAssigments.getAssignments(
        assignments.length,
        filters
      ).then(response => {
        setAssignments([...assignments, ...response.data]);
        setTotal(response.total);
      }).finally(() => {
        setIsLoading(false);
      });
    }
    return { assignments };
  };

  return {
    getAssignments,
    assignments,
    isLoading,
    setAssignments,
  };
}
