import { Button } from "@fluentui/react-components"
import { worktimeHistoryService, usersService } from "../../services"
import { AppSelect, AppUserSelect } from "../common"
import AppDateInput from "../common/AppDateInput"
import styles from "./EmplyeesListStyles"
import { SubmitHandler, useForm } from "react-hook-form"
import { activityStatusPl, localStorageAccessorEnum, userRoleEnum, activityStatus } from "../../utils/enums"
import { useContext, useEffect, useState } from "react"
import { IUserSelectable } from "../../services/types/users"
import { IFiltersData } from "../../services/types/worktimeHistory"
import UserContext from "../../modules/AppRouter/UserContext"

const WorktimeHistoryFilters: React.FC<{setFiltersData: (filters: IFiltersData) => void}> = ({setFiltersData}) => {
    const { register, handleSubmit, reset } = useForm<IFiltersData>();
    const [userOptions, setUserOptions] = useState<IUserSelectable[]>([]);
    const userData = useContext(UserContext);
    const today = new Date().toISOString().split('T')[0];

    const filterData: SubmitHandler<IFiltersData> = (data) => { 
        let activityStatusType: activityStatus | undefined;
        switch (data.status){
          case activityStatusPl.Active:
            activityStatusType = activityStatus.Active;
            break;
          case activityStatusPl.Inactive:
            activityStatusType = activityStatus.Inactive;
            break;
          default:
            activityStatusType = undefined;
            break;
        }

        setFiltersData({
          date: data.date,
          employeeId: data.employeeId,
          status: data.status,
          activityStatus: activityStatusType
        })
      }

      const clearFilters = () => {
        localStorage.removeItem(localStorageAccessorEnum.Filters);
        reset();
        setFiltersData({
          date: "",
          employeeId: "",
          activityStatus: undefined,
          status: undefined
        });
      }

      useEffect(() => {
        usersService.selectableUsers().then((users) => {
          const defaultValue: IUserSelectable = {id: "", firstName: "Wszyscy", lastName: "" }
          setUserOptions([defaultValue ,...users]);
        });
      }, []);

  return (
    <form style={styles.filtersContainer} onSubmit={handleSubmit(filterData)}>
            {userData.data.role === userRoleEnum.Admin &&
            <AppUserSelect
              label="Wybierz pracownika"
              options={userOptions}
              formHandler={register("employeeId")}
            />}
            <AppDateInput 
              label="Data"
              formHandler={register("date")}
              defaultValue={today}
            />
            <AppSelect 
              label="Status"
              formHandler={register("status")}
              options={["Wszystkie", activityStatusPl.Active , activityStatusPl.Inactive]}
              defaultValue={'Wszystkie'}
            />
            <Button 
              style={styles.filterButton}
              type="submit"
            >
              Filtruj
            </Button>
            <Button 
              style={styles.filterButton}
              type="button"
              onClick={clearFilters}
            >
              Wyczyść filtry
            </Button>
          </form>
  )
}

export default WorktimeHistoryFilters
