import {
  makeStyles,
  Select,
  SelectProps,
  useId,
  Label,
} from "@fluentui/react-components";
import { FieldValues } from "react-hook-form";

interface IAppUserSelect {
  label: string;
  options: string[];
  formHandler: FieldValues;
}

const useStyles = makeStyles({
  root: {
    width: "150px",
  },
});

export default function AppSelect(props: IAppUserSelect & SelectProps) {
  const styles = useStyles();
  const dropdownId = useId("select");
  const { label, options, formHandler, ...rest } = props;

  return (
    <div>
      <Label id={dropdownId}>{label}</Label>
      <Select
        aria-labelledby={dropdownId}
        className={styles.root}
        {...formHandler}
        {...rest}
      >
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </Select>
    </div>
  );
}
