import { Button } from "@fluentui/react-components";
import { BackOnePage } from "../../services/Helpers/HelperService";

const buttonStyle: React.CSSProperties = {
  zIndex: "10",
  padding: "10px",
  textAlign: "center",
};

const BackButton = () => {
  return (
    <div style={buttonStyle}>
      <Button onClick={() => BackOnePage()}>Wróć</Button>
    </div>
  );
};

export default BackButton;
