import {
  Input,
  InputProps,
  Label,
  useId,
  makeStyles,
} from "@fluentui/react-components";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";

interface IAppTimeInput {
  label: string;
  formHandler: FieldValues;
  control: Control
  errors: FieldErrors
}

const useStyles = makeStyles({
  root: {
    width: "150px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  errorAlert: {
    color: "red"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column"
  }
});

export default function AppTimeInput(props: IAppTimeInput & InputProps) {
  const styles = useStyles();
  const { label, formHandler, control, errors, ...rest } = props;
  const inputId = useId("number-input");

  return (
    <div className={styles.container}>
      <Label htmlFor={inputId}>{label}</Label>
      <Controller
        name="duration"
        control={control}
        rules={{
          pattern: {
            value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
            message: 'Niewłaściwy format (HH:mm)',
          },
        }}
        render={() => (
          <div className={styles.inputContainer}>
            <Input type="text" maxLength={5} className={styles.root} {...formHandler} {...rest} />
            {errors.duration && <small className={styles.errorAlert}>{errors.duration.message?.toString()}</small>}
          </div>
        )}
      />
    </div>
  );
}
