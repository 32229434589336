import { makeStyles } from '@fluentui/react-components';
import { useCallback } from 'react';
import { assignmentStatus } from '../utils/enums';

const useStyles = makeStyles({
  acceptedTxt: {
    color: 'blue',
  },
  realizedTxt: {
    color: 'green',
  },
  notRealizedTxt: {
    color: 'red',
  },
  rejectedTxt: {
    color: 'darkred',
  },
  partiallyRealizedTxt: {
    color: 'orange',
  },
  defaultTxt: {
    color: 'black',
  },
});

export default function useStatusStyle() {
  const styles = useStyles();

  const checkAssignmentStatus = useCallback((item: assignmentStatus) => {
    switch (item) {
      case assignmentStatus.Accepted:
        return styles.acceptedTxt;
      case assignmentStatus.New:
        return styles.defaultTxt;
      case assignmentStatus.Realized:
        return styles.realizedTxt;
      case assignmentStatus.NotRealized:
        return styles.notRealizedTxt;
      case assignmentStatus.Rejected:
        return styles.rejectedTxt;
      case assignmentStatus.PartiallyRealized:
        return styles.partiallyRealizedTxt;
      default:
        return styles.defaultTxt;
    }
  }, []);
  return {
    checkAssignmentStatus,
    styles,
  };
}
