import ActivityStatus from "../common/ActivityStatus/ActivityStatus";
import MobileLayout from "../layouts/MobileLayout/MobileLayout";

const MobilityActivityStatusPage = () => {
  return (
    <MobileLayout>
      <ActivityStatus />
    </MobileLayout>
  );
};

export default MobilityActivityStatusPage;
