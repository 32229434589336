import { makeStyles } from "@fluentui/react-components";
import React from "react";

const useStyles = makeStyles({
  background: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "300px",
  },
});

const Background: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      <svg
        width="100%"
        height="300px"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1920 0H-7.62939e-06V300H1920V0Z" fill="#393C7B" />
        <g filter="url(#filter0_d_31_216)">
          <path d="M0 0L1427 300H0V0Z" fill="#4B4E8D" />
        </g>
        <g filter="url(#filter1_d_31_216)">
          <path d="M1920 0L473 300H1920V0Z" fill="#4F5298" />
        </g>
        <defs>
          <filter
            id="filter0_d_31_216"
            x="-40"
            y="-48"
            width="1507"
            height="380"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-8" />
            <feGaussianBlur stdDeviation="20" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.124529 0 0 0 0 0.121458 0 0 0 0 0.275 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_31_216"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_31_216"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_31_216"
            x="473"
            y="-62"
            width="1547"
            height="374"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="63" dy="-25" />
            <feGaussianBlur stdDeviation="18.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0125 0 0 0 0 0.0125 0 0 0 0 0.0125 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_31_216"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_31_216"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default Background;
