import { Button, makeStyles } from "@fluentui/react-components";
import { Card, CardPreview } from "@fluentui/react-components/unstable";
import { useState } from "react";
import {
  AddAssignmentDialog,
  AssignmentsList,
  CenteredLayout,
  OvertimeStatusCard,
} from "../../../components/layouts";
import useAsignments from "../../../hooks/useAsignments";
import { AppLink } from "../../../components/common";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "2rem",
    marginTop: "9rem",
  },
  instructionBtn: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    zIndex: "9",
    height: "2rem",
    width: "6rem",
  },
});

const ManageOvertime = () => {
  const styles = useStyles();
  const { assignments, getAssignments, isLoading, setAssignments } =
    useAsignments();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Button className={styles.instructionBtn}>
        <AppLink to="quick-start" text="Instrukcja" />
      </Button>
      <CenteredLayout>
        <AddAssignmentDialog
          open={openDialog}
          setOpen={setOpenDialog}
          getAssignments={getAssignments}
          setAssignments={setAssignments}
        />
        <div className={styles.container}>
          <OvertimeStatusCard />
          <Card>
            <CardPreview>
              <AssignmentsList
                tableHeadTxt="Wnioski i zlecenia"
                items={assignments}
                getMoreUsers={getAssignments}
                isLoading={isLoading}
                setOpendialog={setOpenDialog}
              />
            </CardPreview>
          </Card>
        </div>
      </CenteredLayout>
    </>
  );
};

export default ManageOvertime;
