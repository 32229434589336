import styles from "./EmplyeesListStyles";
import {
  useScrollbarWidth,
  useFluent,
  Button,
} from "@fluentui/react-components";
import * as React from "react";
import { FixedSizeList as List } from "react-window";
import {
  TableBody,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableSelectionCell,
  createTableColumn,
  useTableFeatures,
  useTableSelection,
  TableRowData as RowStateBase,
  Card,
  CardPreview,
} from "@fluentui/react-components/unstable";
import { UserListRow } from "../businessComponenets/";
import InfiniteLoader from "react-window-infinite-loader";
import { IUser, TableProps } from "../../services/types/table";
import usersService from "../../services/users.service";
import { AppButton, AppLoader } from "../common";

interface TableRowData extends RowStateBase<IUser> {
  onClick: (e: React.MouseEvent) => void;
  getUsers: () => void;
  selected: boolean;
  appearance: "brand" | "none";
  assigned: boolean;
}

const columns = [
  createTableColumn<IUser>({
    columnId: "userName",
  }),
];

const EmployeesList: React.FC<TableProps> = ({
  tableHeadTxt,
  items,
  getMoreUsers,
  assigned,
  isLoading,
}) => {
  const [isAdding, setIsAdding] = React.useState(false);
  const { targetDocument } = useFluent();
  const scrollbarWidth = useScrollbarWidth({
    targetDocument,
  });
  const {
    getRows,
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSelection({
        selectionMode: "multiselect",
      }),
    ]
  );

  const rows: TableRowData[] = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      getUsers: () => {
        items.length = 0;
        getMoreUsers(0, true);
      },
      onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row.rowId);
        }
      },
      selected,
      appearance: selected ? ("brand" as const) : ("none" as const),
      assigned: assigned,
    };
  });

  const isItemLoaded = () => {
    return false;
  };

  const loadMoreItems = (_: number, stopIndex: number) => {
    getMoreUsers(stopIndex, assigned);
  };
  const addMembersToApp = () => {
    setIsAdding(true);
    const selected = getSelectedUsers();
    usersService.addUsersToApp(selected, setIsAdding);
  };
  const getSelectedUsers = (): IUser[] => {
    const allRows = getRows();
    const selected = allRows.filter((row) => isRowSelected(row.rowId));
    return selected.map((e) => {
      return e.item;
    });
  };
  return (
    <Card style={styles.employeesCard}>
      <CardPreview>
        <Table
          noNativeElements
          aria-label="Tabela z wyborem"
          aria-rowcount={rows.length}
          style={styles.employeTable}
        >
          <TableHeader>
            <TableRow style={styles.tableHeader}>
              <h3 style={styles.tableTxt}>{tableHeadTxt}</h3>
              {!assigned && (
                <AppButton
                  text="Dodaj"
                  disabled={isAdding}
                  appearance="primary"
                  onClick={addMembersToApp}
                />
              )}
            </TableRow>
            <TableRow aria-rowindex={1}>
              {!assigned && (
                <TableSelectionCell
                  checked={
                    allRowsSelected ? true : someRowsSelected ? "mixed" : false
                  }
                  onClick={toggleAllRows}
                  checkboxIndicator={{
                    "aria-label": "Zaznacz wszystkie",
                  }}
                />
              )}
              <TableHeaderCell>Nazwisko i imię</TableHeaderCell>
              <div
                role="presentation"
                style={{
                  width: scrollbarWidth,
                }}
              />
            </TableRow>
          </TableHeader>
          <TableBody>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={items.length}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={330}
                  itemCount={items.length}
                  itemSize={45}
                  width="100%"
                  itemData={rows}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {UserListRow}
                </List>
              )}
            </InfiniteLoader>
            {isLoading && <AppLoader />}
          </TableBody>
        </Table>
      </CardPreview>
    </Card>
  );
};
export default EmployeesList;
