import React from 'react'
import { LandingPageLayout } from '../../components/layouts'
import { Button, makeStyles } from '@fluentui/react-components';
import {
    instrImg1,
    instrImg2,
    instrImg3,
    instrImg4,
    instrImg5,
    instrImg6,
    instrImg7,
    instrImg8,
    instrImg9,
    instrImg10,
    instrImg11,
    instrImg12,
    instrImg13,
    instrImg14,
    instrImg15,
    instrImg16,
    instrImg17,
    instrImg18,
    instrImg19,
    instrImg20,
    instrImg21,
    instrImg22,
    instrImg23,
    instrImg24,
    instrImg25, 
    instrImg26,
    instrImg27,
    instrImg28,
    instrImg29,
    instrImg30,
    instrImg31,
    instrImg32,
    instrImg33,
    instrImg34,
    instrImg35,
    instrImg36,
    instrImg37,
    instrImg38,
    instrImg39,
    instrImg40} from "../../assets/instruction"
    

const useStyles = makeStyles({
    container: {
      textAlign: "left",
      maxWidth: "60rem",
      fontSize: "1rem",
      lineHeight: "1.5",
      marginTop: "3rem"
    },
    textBlock: {
        marginBottom: "2rem"
    },
    previewImg: {
        maxWidth: "100%",
        marginBlock: "1rem"
    },
    btnBack: {
        position: "fixed",
        top: "1rem",
        left: "1rem"
    }
  });

const AppInstruction: React.FC = () => {
    const styles = useStyles();

    const backTreviousWindow = () => {
        window.history.back();
    }

  return (
    <>
        <Button className={styles.btnBack} onClick={backTreviousWindow}>Wróć</Button>
        <LandingPageLayout>
            <main className={styles.container}>
                <header className={styles.textBlock}>
                    <h1>Overtimer</h1>
                    <h3>Instrukcja korzystania z aplikacji</h3>
                </header>
                <section className={styles.textBlock}> 
                    <h3>Wstęp</h3>
                    <p>Aplikacja Overtimer to narzędzie stworzone z myślą o maksymalnym uproszczeniu procesu
                        komunikacji i zarządzania nadgodzinami. Dzięki intuicyjnemu interfejsowi użytkowników i
                        rozbudowanym funkcjom, aplikacja umożliwia błyskawiczne zgłaszanie i monitorowanie nadgodzin, a
                        administratorom dostarcza szczegółowe raporty miesięczne. Niezależnie od tego, czy jesteś
                        pracownikiem czy administratorem, ta aplikacja zapewni Ci wygodę i efektywność w zarządzaniu
                        nadgodzinami.
                    </p>
                </section>
                <section className={styles.textBlock}>
                    <h3>Tryb Użytkownika</h3>
                    <ol>
                        <li>
                            Podczas pierwszego uruchomienia aplikacji nie mając uprawnień administratora oraz jeśli administrator 
                            nie dodał użytkownika do organizacji pojawi się ekran z informacją aby skontaktować się z administratorem 
                            w celu uzyskania dostępu do aplikacji.
                        </li>
                        <li>
                            Jeśli administrator dodał użytkownika do aplikacji pojawi się panel użytkownika z dwoma kartami: 
                            “Nadgodziny” oraz “Wnioski i zlecenia”
                        </li>
                        <img src={instrImg14} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Aby zaraportować ilość odebranych nadgodzin kliknij ”ołówek” w karcie ”Nadgodziny”
                        </li>
                        <li>
                            Pojawi się okno z edycją nadgodzin. Ustaw liczbę nadgodzin, które odebrałeś a następnie kliknij ”Zapisz”. 
                        </li>
                        <img src={instrImg15} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Po poprawnym zapisaniu zmian do liczby odebranych nadgodzin zostanie dodana liczba, którą wcześniej podałeś. 
                        </li>
                        <img src={instrImg16} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Aby wejść w podgląd zlecenia kliknij w wybrane zlecenie na liście.
                        </li>
                        <li>
                            Jeżeli jest to nowe zlecenie możesz je zaakceptować lub odrzucić. W tym celu naciśnij 
                            odpowiedni przycisk na podglądzie zlecenia.
                        </li>
                        <img src={instrImg17} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            W przypadku odrzucenia zlecenia podaj powód, a następnie kliknij przycisk ”Odrzuć”
                        </li>
                        <img src={instrImg18} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Po poprawnym przeprowadzeniu operacji status zlecenia zmieni się.
                        </li>
                        <img src={instrImg19} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Następnie możesz rozliczyć się z wykonania zlecenia oznaczając status jako ”Zrealizowano” lub ”Nie zrealizowano”.
                        </li>
                        <img src={instrImg20} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            W przypadku niezrealizowania zlecenia kliknij przycisk ”Nie zrealizowano”, 
                            w wyświetlonym oknie podaj powód, a następnie kliknij przycisk wyślij.
                        </li>
                        <li>
                            Jeśli zlecenie zostało zrealizowane kliknij przycisk ”Rozlicz zlecenie”.
                        </li>
                        <li>
                            W wyświetlonym oknie podaj datę, ilość godzin jakie udało Ci się zrealizować oraz linki do 
                            zrealizowanych zadań z systemu zarządzania projektami.
                        </li>
                        <li>
                            Aby dodać link wklej go w pole tekstowe, a następnie naciśnij ”+”.
                        </li>
                        <li>
                            Link pojawi się na liście. 
                        </li>
                        <li>
                            Aby usunąć błednie wprowadzony link kliknij "-" przy wybranym linku. 
                        </li>
                        <img src={instrImg21} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            W celu zatwierdzenia kliknij ”Rozlicz”
                        </li>
                        <li>
                            Po poprawnym przeprowadzeniu operacji status zadania zostanie zaktualizowany, 
                            a w podglądzie pojawią się informacje na temat zrealizowanego zadania.
                        </li>
                        <li>
                            Aby wejść w podgląd wpisów kliknij przycisk “Historia odebranych nadgodzin”.
                        </li>
                        <img src={instrImg22} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            W podglądzie znajduje się historia wszystkich wpisów jakie dokonałeś.
                        </li>
                        <img src={instrImg23} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            w celu powrotu do poprzedniego widoku kliknij przycisk ”Powrót”.
                        </li>
                        <img src={instrImg24} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Aby przefiltrować listę zleceń ustaw filtry, które chcesz dodać z tych, które są dostępne w nagłówku tabeli 
                            ”Wnioski i zlecenia”, a następnie kliknij przycisk ”Filtruj”.
                        </li>
                        <img src={instrImg25} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            W przypadku chęci wyjścia z pracy lub złożenia wniosku o nadgodziny 
                            kilknij przycisk "Dodaj" znajdujący się w nagłówku tabeli.
                        </li>
                        <img src={instrImg26} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            W oknie modalnym określ datę, liczbę godzin oraz typ wniosku.
                        </li>
                        <img src={instrImg27} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            W przypadku wybrania wniosku o wyjście pojawi się dodatkowa opcja "Odrabiam tego samego dnia".
                            Po zaznaczeniu tej opcji, gdy administrator zaakceptuje wniosek, automatycznie wygeneruje się zlecenie wykonania nadgodzin
                            tego samego dnia.
                        </li>
                        <img src={instrImg28} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            Po poprawnym dodaniu, wniosek pojawi się na liście ze statusem "Nowe".
                        </li>
                        <img src={instrImg29} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            Jeśli administrator zaakceptuje Twój wniosek jego status zmieni się na "Zaakceptowane". 
                            W celu rozliczenia kliknij wybrany wniosek, a następnie kliknij "Rozlicz".
                        </li>
                        <img src={instrImg30} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            W oknie modalnym podaj rzeczywisty zrealizowany czas, a następnie potwierdź wykonanie operacji ponownie klikając "Rozlicz".
                        </li>
                        <img src={instrImg31} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            Jeśli chcesz sprawdzić historię swoich wyjść kliknij w przycisk "Historia wyjść".
                        </li>
                        <img src={instrImg32} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            Pojawi się widok ze wszystkimi wpisami dotyczącymi Twoich wyjść. Aby wrócić do poprzedniego widoku kliknij "Powrót".
                        </li>
                        <img src={instrImg33} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            W przypadku zaznaczenia opcji "Odrabiam tego samego dnia" w podglądzie wniosku o wyjście pojawi się link do zlecenia nadgodzin 
                            gdzie możesz rozliczyć godziny odrobione za to wyjście.
                        </li>
                        <img src={instrImg37} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            W przypadku rozliczenia tylko wyjścia lub tylko odpracowania nadgodzin wniosek będzie miał status "Częściowo zrealizowane".
                        </li>
                        <img src={instrImg38} alt="Podgląd widoku" className={styles.previewImg}/>
                        <li>
                            Możesz edytować lub usunąć błednie wprowdzony wpis do momentu zaakceptowania go przez administratora korzystając z przycisków "Usuń wpis" 
                            na liście wpisów i przycisku "ołówka" w podglądzie wpisu.
                        </li>
                        <img src={instrImg39} alt="Podgląd widoku" className={styles.previewImg}/>
                        <img src={instrImg40} alt="Podgląd widoku" className={styles.previewImg}/>
                    </ol>
                </section>
                <section className={styles.textBlock}>
                    <h3>Tryb Administratora</h3>
                    <ol>
                        <li>Podczas pierwszego uruchomienia aplikacji mając uprawnienia administratora organizacji
                            pojawia się ekran z możliwością dodania nowej organizacji do aplikacji.
                        </li>
                        <img src={instrImg1} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Kliknij przycisk ”Zarejestruj organizację” aby dodać Twoją organizację do aplikacji.
                        </li>
                        <li>
                            Organizacja zostanie dodana do aplikacji, a administrator zostanie dodany automatycznie do
                            użytkowników z przypisaną rolą administratora.
                        </li>
                        <li>
                            Po poprawnym dodaniu organizacji nastąpi przekierowanie do panelu administratora.
                        </li>
                        <li>
                            W panelu administratora na górnym pasku pojawią się czery zakładki: ”Pracownicy”, ”Zlecenia”,
                            ”Raporty” oraz ”Historia odebranych nadgodzin.
                        </li>
                        <img src={instrImg2} alt='Podgląd widoku' className={styles.previewImg}/>
                        <li>
                            Zakładka ”Pracownicy” umożliwia dodawanie członków organizacji do aplikacji
                        </li>
                        <img src={instrImg3} alt='Podgląd widoku' className={styles.previewImg}/>
                        <ol>
                            <li>
                                Aby dodać członka organizacji do aplikacji zaznacz wybranego pracownika a następnie kliknij przycisk ”Dodaj”.
                            </li>
                            <li>
                                Po pomyślnym dodaniu pracowników do aplikacji pojawią się oni w tabeli ”Pracownicy dodani do organizacji”.
                            </li>
                            <li>
                                Możesz dodać wszystkich pracowników zaznaczając “checkbox” na górze tabeli, a następnie klikając “Dodaj”
                            </li>
                            <img src={instrImg4} alt='Podgląd widoku' className={styles.previewImg}/>
                        </ol>
                        <li>
                            Zakładka ”Zlecenia” umożliwia stworzenie nowego zlecenia nadgodzin.
                        </li>
                        <ol>
                            <li>
                                Aby stworzyć nowe zlecenie kliknij przycisk ”Dodaj” znajdujący prawym rogu tabeli.
                            </li>
                            <img src={instrImg5} alt='Podgląd widoku' className={styles.previewImg}/>
                            <li>
                                Pojawi się okno z formularzem zlecenia nadgodzin, z domyślnie wypełnionymi polami zlecenia.
                            </li>
                            <li>
                                Edytuj pola, a następni kliknij ”Dodaj”.
                            </li>
                            <li>
                                Zlecenie pojawi się w tabeli ”Wnioski i zlecenia”
                            </li>
                            <img src={instrImg6} alt='Podgląd widoku' className={styles.previewImg}/>
                            <li>
                                Aby włączyć podgląd zlecenia kliknij na wybrane zlecenie wewnątrz tabeli.
                            </li>
                            <li>
                                Pojawi się podgląd z informacjami na temat zlecenia oraz przyciskiem powrotu do poprzedniego widoku.
                            </li>
                            <img src={instrImg7} alt='Podgląd widoku' className={styles.previewImg}/>
                            <li>
                                Aby wrócić do poprzedniego widoku kliknij przycisk ”Powrót do listy zleceń”
                            </li>
                            <li>
                                Aby filtrować listę zleceń ustaw wybrane filtry z filtrów zawartych w nagłówku tabeli,
                                a następnie kliknij przycisk ”Filtruj”
                            </li>
                            <img src={instrImg8} alt='Podgląd widoku' className={styles.previewImg}/>
                            <li>
                                Wnioski o nadgodziny/wyjście oczekujące na akceptację mają status "Nowe" oraz typ "Wniosek o wyjście/nadgodziny".
                            </li>
                            <img src={instrImg34} alt='Podgląd widoku' className={styles.previewImg}/>
                            <li>
                                Kliknij w wybrany wniosek aby wyświetlić jego szczegóły. Pole "Rodzaj wniosku" określa czy jest to wniosek o wyjście 
                                czy nadgodziny.
                            </li>
                            <img src={instrImg35} alt='Podgląd widoku' className={styles.previewImg}/>
                            <li>
                                Aby zaakceptować lub odrzucić wniosek skorzystaj z przycisków znajdujących się na dole karty.
                            </li>
                            <li>
                                W przypadku wniosku o wyjście, w którym pracownik zaznaczył, że odrabia dzisiaj, automatycznie zostanie wygenerowane 
                                zlecenie nadgodzin z datą i liczbą godzin taką samą jak we wniosku o wyjście.
                            </li>
                            <img src={instrImg36} alt='Podgląd widoku' className={styles.previewImg}/>
                        </ol>
                        <li>
                            Zakładka ”Raporty” umożliwia wygenerowanie raportu z wybranego miesiąca.
                        </li>
                        <img src={instrImg9} alt='Podgląd widoku' className={styles.previewImg}/>
                        <ol>
                            <li>
                                Wybierz miesiąc i rok, z którego chcesz wygenerować raport, a następnie kliknij przycisk ”Generuj raport”.
                            </li>
                            <li>
                                Zostanie pobrany plik .xlsx zawierający raport z wybranego miesiąca.
                            </li>
                            <img src={instrImg10} alt='Podgląd widoku' className={styles.previewImg}/>
                        </ol>
                        <li>
                            Zakładka ”Historia odebranych nadgodzin” umożliwia przeglądanie wpisów odbieranych nadgodzin przez pracowników.
                        </li>
                        <ol>
                            <li>
                                Aby usunąć wpis naciśnij przycisk ”Usuń wpis” obok wybranego wpisu, a następnie potwierdź usunięcie
                                wpisu w oknie, które się pojawi.
                            </li>
                            <img src={instrImg11} alt='Podgląd widoku' className={styles.previewImg}/>
                            <img src={instrImg12} alt='Podgląd widoku' className={styles.previewImg}/>
                            <li>
                                Aby filtrować pracowników wybierz pracownika, którego chcesz wyświetlić z listy.
                            </li>
                            <img src={instrImg13} alt='Podgląd widoku' className={styles.previewImg}/>
                        </ol>
                        <li>
                            Zakładka ”Historia wyjść” umożliwia przeglądanie wpisów wyjść przez pracowników oraz usuwanie ich tak jak ma to miejsce w przypadku
                            zakładki "Historia odebranych nadgodzin".
                        </li>
                    </ol>
                </section>
            </main>
        </LandingPageLayout>
    </>
  )
}

export default AppInstruction