import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
  makeStyles,
  Textarea,
  TextareaProps,
  tokens,
  useId,
} from "@fluentui/react-components";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { overtimeAssigments } from "../../services";
import { assignmentStatus } from "../../utils/enums";
import appErrorToast from "../common/AppErrorToast";
import { AppButton } from "../common";

interface IUnrealizedAssignmentdialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
  reload: boolean;
}
const useStyles = makeStyles({
  base: {
    display: "flex",
    flexDirection: "column",
    "& > label": {
      display: "block",
      marginBottom: tokens.spacingVerticalMNudge,
    },
  },
  textarea: {
    height: "6rem",
  },
});

const UnrealizedAssignmentDialog: React.FC<
  IUnrealizedAssignmentdialogProps
> = ({ open, setOpen, setReload, reload }) => {
  const textareaId = useId("textarea");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const styles = useStyles();
  const { id } = useParams<{ id: string }>();

  const onChange: TextareaProps["onChange"] = (_, data) => {
    if (data.value.length <= 500) {
      setDescription(data.value);
    }
  };
  const onSubmit = () => {
    if (description.length > 1) {
      setIsLoading(true);
      overtimeAssigments.updateAssignment(
        assignmentStatus.NotRealized,
        description,
        id
      ).then(() => {
        setDescription("");
        setOpen(false);
        setReload(!reload);
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      appErrorToast("Podaj powód nie zrealizowania");
    }
  };

  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Oznacz jako niezrealizowane</DialogTitle>
          <DialogContent>
            <div className={styles.base}>
              <Label htmlFor={textareaId}>
                Podaj powód niezrealizowania zlecenia
              </Label>
              <Textarea
                textarea={{ className: styles.textarea }}
                value={description}
                onChange={onChange}
                id={textareaId}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => setDescription("")}>
                Anuluj
              </Button>
            </DialogTrigger>
            <AppButton
              text="Wyślij"
              disabled={isLoading}
              appearance="primary"
              onClick={onSubmit}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default UnrealizedAssignmentDialog;
