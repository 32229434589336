import React, { useEffect, useRef, useState } from "react";
import { Persona, Spinner } from "@fluentui/react-components";
import { UserActivity } from "../../../../services/worktime.service";
import { app } from '@microsoft/teams-js';

interface ActivityStatusCardProps {
  user: UserActivity;
}

const ActivityStatusCard: React.FC<ActivityStatusCardProps> = (props) => {
  const personaRef = useRef(null);
  const { user } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (personaRef.current) {
      const { offsetWidth, offsetHeight } = personaRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  const handleCardClick = () => {
    if (!isLoading) {
      setIsLoading(true);
      const chatDeepLink = `https://teams.microsoft.com/l/chat/0/0?users=${encodeURIComponent(user.email)}`;
      app.openLink(chatDeepLink).finally(() => {
        setIsLoading(false); 
      });
    }
  };

  const cardStyle: React.CSSProperties = {
    backgroundColor: user.isActive || isLoading ? "#fff" : "rgb(203 203 203)",
    borderRadius: "10px",
    padding: "10px",
    margin: "10px",
    cursor: "pointer",
  };

  return (
    <div onClick={handleCardClick} style={cardStyle} className="teams-card">
      {isLoading ? (
        <Spinner size="medium" style={{ width: dimensions.width, height: dimensions.height }}/>
      ) : (
        <div ref={personaRef}>
          <Persona
            name={`${user.firstName} ${user.lastName}`}
            secondaryText={user.isActive ? "Dostępny" : "Niedostępny"}
            presence={{ status: user.isActive ? "available" : "offline" }}
            size="large"
            textPosition="after"
            textAlignment="center"
          />
        </div>
      )}
    </div>
  );
};

export default ActivityStatusCard;
