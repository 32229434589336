import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, DialogTrigger, Button, Spinner } from '@fluentui/react-components';
import React from 'react'
import { IModificationLogDetails } from '../../../services/types/table';
import './LoggedModificationDialog.css'
import dateFormatService from '../../../services/dateFormat.service';


interface IAppDialogProps {
    title: string,
    content: IModificationLogDetails[],
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    open: boolean,
    isLoading: boolean
}

const LoggedModificationDialog: React.FC<IAppDialogProps> = ({
    title,
    content,
    setOpen,
    open,
    isLoading
}) => {
    return (
        <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle className="dialog-title">{title}</DialogTitle>
                    <DialogContent className="dialog-content">
                    { isLoading ? <Spinner size="huge" className='spinner-style'/> : (
                    <table className="table">
                        <tbody>
                            <tr className="table-row">
                                <td className="table-cell label">Początek czasu przed modyfikacją</td>
                                {content.map((item, index) => (
                                    <td key={index} className="table-cell value">{dateFormatService.formatDateToTime(item.worktimeBeginningBefore)}</td>
                                ))}
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell label">Koniec czasu przed modyfikacją</td>
                                {content.map((item, index) => (
                                    <td key={index} className="table-cell value">{dateFormatService.formatDateToTime(item.worktimeFinishBefore)}</td>
                                ))}
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell label">Modyfikował</td>
                                {content.map((item, index) => (
                                    <td key={index} className="table-cell value">{item.fullname}</td>
                                ))}
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell label">Data modyfikacji</td>
                                {content.map((item, index) => (
                                    <td key={index} className="table-cell value">{`${dateFormatService.formatDate(item.modifiedAt)} ${dateFormatService.formatDateToTime(item.modifiedAt)}`}</td>
                                ))}
                            </tr>
                            <tr className="table-row">
                                <td className="table-cell label">Powód modyfikacji</td>
                                {content.map((item, index) => (
                                    <td key={index} className="table-cell value">{item.reasonForEdit}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>)}
                    </DialogContent>
                    <DialogActions className="dialog-actions">
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" className="cancel-button">Anuluj</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default LoggedModificationDialog
