import { appSuccessToast } from "../components/common";
import axiosInstance from "../utils/axios";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api`;

class OrganizationsService {
  registerOrganization() {
    axiosInstance.post(`${pathPrefix}/organizations`).then(() => {
      appSuccessToast("Organizacja została zarejestrowana");
      setTimeout(() => {
        window.location.href = "admin/manage-employees";
      }, 1000);
    });
  }
}

const organizationsService = new OrganizationsService();
export default organizationsService;
