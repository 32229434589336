import { Body1, Button } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { IOvertimeSummaries } from "../../services/types/users";
import { overtimeSummariesService } from "../../services";
import { makeStyles } from "@fluentui/react-components";
import dataTimeService from "../../services/dataTime.service";
import { AssignemntCardRow, OvertimeDialog } from "../layouts";
import { useHistory } from "react-router-dom";
import { Card } from "@fluentui/react-components/unstable";

const useStyles = makeStyles({
  cardRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardContainer: {
    width: "100%",
    zIndex: "5",
    "@media (min-width: 966px)": {
      width: "50%",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  overtimeHistoryButton: {
    float: "right",
    marginLeft: "1rem",
  },
});

const initialObject: IOvertimeSummaries = {
  timeToPay: 0,
  timeToCollection: 0,
  timeToCollectionSummary: 0,
  timeToPaySummary: 0,
};

const OvertimeStatusCard: React.FC = () => {
  const [overtimeSummaries, setOvertimeSummaries] =
    useState<IOvertimeSummaries>(initialObject);
  const classes = useStyles();
  const history = useHistory();

  const getOvertime = () => {
    overtimeSummariesService.getOvertimeSummaries().then((result) => {
      setOvertimeSummaries(result);
    });
  };

  useEffect(() => {
    getOvertime();
  }, []);

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <h3>Nadgodziny</h3>
        <OvertimeDialog
          timeToCollection={overtimeSummaries.timeToCollection}
          timeToPay={overtimeSummaries.timeToPay}
          timeToPaySummary={overtimeSummaries.timeToPaySummary}
          timeToCollectionySummary={overtimeSummaries.timeToCollectionSummary}
          getOvertime={getOvertime}
        />
      </div>
      <Body1>
        <AssignemntCardRow
          title="Odebrane godziny"
          txt={dataTimeService.convertToTimeFormat(
            overtimeSummaries.timeToCollection
          )}
        />
        <AssignemntCardRow
          title="Suma godzin do odbioru"
          txt={dataTimeService.convertToTimeFormat(
            overtimeSummaries.timeToCollectionSummary
          )}
        />
        <AssignemntCardRow
          title="Suma godzin do wypłaty"
          txt={dataTimeService.convertToTimeFormat(
            overtimeSummaries.timeToPaySummary
          )}
        />
        <Button
          className={classes.overtimeHistoryButton}
          appearance="primary"
          onClick={() => {
            history.push(`/collected-time-history`);
          }}
        >
          Historia odebranych nadgodzin
        </Button>
        <Button
          className={classes.overtimeHistoryButton}
          appearance="primary"
          onClick={() => {
            history.push(`/leavings-history`);
          }}
        >
          Historia wyjść
        </Button>
      </Body1>
    </Card>
  );
};

export default OvertimeStatusCard;
