import { AppButton } from "../../components/common";
import AppHeader from "../../components/common/AppHeader";
import organizationsService from "../../services/organizations.service";

export default function RegisterOrganisation() {
  return (
    <>
      <AppHeader text="Twoja organizacja nie korzysta z aplikacji" />
      <AppButton
        onClick={organizationsService.registerOrganization}
        text="Zarejestruj organizację"
      />
    </>
  );
}
