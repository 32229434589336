import { EmployeesList, CenteredLayout } from '../../../components/layouts';
import useUnassignedUsers from '../../../hooks/useUnassignedUsers';
import AdminNavbar from '../../../components/layouts/AdminNavbar';
import { makeStyles } from '@fluentui/react-components';
import PageTitle from '../../../components/common/PageTitle';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: "column",
    columnGap: '2rem',
    width: '100%',
    "@media (min-width: 966px)": {
      flexDirection: "row"
    },
  },
});

const ManageEmployees = () => {
  const unAssignedUsers = useUnassignedUsers(false);
  const assignedUsers = useUnassignedUsers(true);
  const styles = useStyles();

  return (
    <>
      <AdminNavbar />
      <CenteredLayout>
        <PageTitle title="Pracownicy" />
        <div className='table-container'>
          <div className={styles.container}>
            <EmployeesList
              tableHeadTxt="Pracownicy oczekujący na dodanie"
              items={unAssignedUsers.unassignedUsers}
              getMoreUsers={unAssignedUsers.getAssignedUsers}
              assigned={false}
              isLoading={unAssignedUsers.isLoading}
            />
            <EmployeesList
              tableHeadTxt="Pracownicy dodani do organizacji"
              items={assignedUsers.unassignedUsers}
              getMoreUsers={assignedUsers.getAssignedUsers}
              assigned={true}
              isLoading={assignedUsers.isLoading}
            />
          </div>
        </div>
      </CenteredLayout>
    </>
  );
};

export default ManageEmployees;
