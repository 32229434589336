import React from "react";

interface IMobileLayoutProps {
  children?: React.ReactNode;
}

const background: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  background: `linear-gradient(to bottom, #393c7b, #4b4e8d, #4f5298), 
  linear-gradient(
    45deg,
    rgba(124, 123, 188, 0.6) 25%, transparent 25%,
    transparent 75%,
    rgba(124, 123, 188, 0.6) 75%
  )`,
  justifyContent: "center",
};

const contentStyle: React.CSSProperties = {
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  height: "100dvh",
};

const MobileLayout: React.FC<IMobileLayoutProps> = (props) => {
  return (
    <div style={background}>
      <div style={contentStyle}>{props.children}</div>
    </div>
  );
};

export default MobileLayout;
